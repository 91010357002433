@import "./src/styles/helpers/_index.scss";
.root.root.root {
  min-height: 300px;
  display: flex;
  grid-gap: 20px;

  .selectors {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    flex: 1 1 400px;
  }

  .uploader {
    width: 100%;
    flex: 1 4 auto;
  }
}
