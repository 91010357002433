@import "./src/styles/helpers/_index.scss";
.root {
  position: fixed;
  z-index: 1010;

  .lastUpdateTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    font-size: 14px;
    color: var(--color-text-grey);
  }
}
