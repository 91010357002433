@import "./src/styles/helpers/_index.scss";
.root.root.root.root {
  width: 32px;
  height: 32px;
  color: $main-dark-grey;
  background-color: transparent;

  &:hover, &:focus {
    --focus-ring: 0 0 0 0.2rem rgba(96, 125, 139, 0.07);

    box-shadow: var(--focus-ring);
    border-color: transparent;
    background-color: #e9ecef;
    color: #495057;
  }

  &.xsmallSize {
    --focus-ring: none;

    width: auto;
    height: auto;
    font-size: 0;
    border: none;

    :global(.p-button-icon) {
      font-size: 12px;
    }
  }

  &.smallSize {
    --focus-ring: none;

    width: auto;
    height: auto;
    font-size: 0;
    border: none;

    :global(.p-button-icon) {
      font-size: 14px;
    }
  }
}
