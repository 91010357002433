@import "./src/styles/helpers/_index.scss";
.Layout {
  display: flex;
  flex-basis: 100%;
}

.Administration {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100vh - 65px);
}

.Content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  padding-left: $administration-sidebar-width + 50px;
  padding-bottom: 0px;
}
