@import "./src/styles/helpers/_index.scss";
.root {
  --color-background: var(--color-basic-white);
  --color-main-text: var(--color-text-dark-grey);
  --color-additional-text: var(--color-text-grey);
  --color-label: var(--color-basic-white);

  width: 220px;
  height: 220px;
  padding: 25px;
  position: relative;
  border-radius: 15px;
  background-color: var(--color-background);
  color: var(--color-additional-text);

  &.disabled {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .header .logo:after {
      background-color: rgb(204, 204, 204);
    }
  }

  .colorLabel {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 0 50% 0 0;
    background-color: currentColor;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
    color: var(--color-label);
  }

  .header {
    height: 58px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;

    .logo {
      max-height: 55px;
      max-width: 170px;
      position: relative;
      overflow: hidden;

      &:after {
        content: attr(alt);
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color-background);
      }
    }

    .name,
    .logo:after {
      overflow: hidden;
      overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      z-index: 1;
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
