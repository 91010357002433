@import "./src/styles/helpers/_index.scss";
.root.root {
  .label {
    margin-bottom: 20px;
    color: $text-dark-grey;
    font-size: 12px;
    font-weight: 500;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .colorButton + .colorButton {
      margin-left: 10px;
    }
  }
}


