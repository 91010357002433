@import "./src/styles/helpers/_index.scss";
.Checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  outline: none;

  &:focus {
    box-shadow: 0 0 3px 1px #1c84c6, 0 0 3px 1px #1c84c6 inset;
  }
}

.RealInput {
  display: none;
}

.Box {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  border: 2px solid #878787;
  border-radius: 2px;
  background-color: $basic-white;
  text-align: initial;
  cursor: pointer;

  &Checked {
    border-color: $green;
    background-color: $green;

    &Blue {
      border-color: $blue;
      background-color: $blue;
    }

    .CheckIcon {
      visibility: visible;
    }
  }
}

input[type=checkbox]:disabled ~ .Box {
  cursor: default;
}

.CheckIcon {
  color: white;
  font-size: 8px;
  line-height: 7px;
  visibility: hidden;
}

.CheckboxLarge {
  .Box {
    width: 18px;
    height: 18px;
    border-color: $basic-dark;

    &Checked {
      border-color: $green;

      &Blue {
        border-color: $blue;
      }
    }
  }

  .CheckIcon {
    font-size: 9px;
    line-height: 8px;
  }
}
