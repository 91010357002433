@import "./src/styles/helpers/_index.scss";
.Layout {
  width: 100%;
  position: relative;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 30px;
  margin-bottom: 20px;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.15;
  color: $text-dark-blue;
}

.Content {
  padding: 25px 35px;

  background-color: $basic-white;
  border-radius: 15px;
}

.ColorSection {
  border-top: 1px solid $basic-light-grey;
}
