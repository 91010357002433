@import "./src/styles/helpers/_index.scss";
.calendar.calendar {
  .calendarInput {
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #b6bfc8;

    &::-webkit-input-placeholder {
      color: inherit;
    }
  }

  &.calendarInvalid {
    .calendarInput {
      background-color: var(--color-red-hover);
      border-color: var(--color-red-hover);
      color: var(--color-basic-white);
    }

    :global(.p-button-icon) {
      color: var(--color-basic-white);
    }
  }
}
