@import "./src/styles/helpers/_index.scss";
.root {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;

  .title {
    font-size: 14px;
  }

  .value {
    display: inline-block;
    min-width: 70px;
    margin-right: 10px;
    font-weight: 500;

    &,
    * {
      font-size: 14px;
    }
  }
}
