@import "./src/styles/helpers/_index.scss";
.root {
  .input {
    margin: 0 18px 18px;
    font-weight: 700;
    color: #495057;
  }

  .columns {
    display: flex;
  }

  .column {
    flex: 1;
  }

  .listbox {
    border: none;
    user-select: none;

    &Disabled li {
      cursor: default;

      &:focus {
        box-shadow: none !important;
      }

      &:hover[aria-selected=false],
      &:focus[aria-selected=false] {
        background-color: unset !important;
      }
    }

    :global(.p-listbox-header) {
      background-color: transparent;
    }

    :global(.p-listbox-list-wrapper) {
      height: calc(min(800px, 50vh));
    }
  }
}
