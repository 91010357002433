@import "./src/styles/helpers/_index.scss";
.root {
  padding-bottom: 20px;
  display: grid;
  grid-template-areas:
    'summary actions'
    'filters actions';
  justify-content: space-between;
  justify-items: flex-start;
  grid-gap: 10px 15px;
}

.summary {
  grid-area: summary;
}

.actions {
  grid-area: actions;
}

.filters {
  grid-area: filters;
  align-self: flex-end;
}
