@import "./src/styles/helpers/_index.scss";
.Page {
  margin-bottom: 40px;
}

.Group {
  margin-bottom: 40px;
  &Label {
    margin-bottom: 12px;
    font-size: 16px;
  }
}
