@import "./src/styles/helpers/_index.scss";
.root.root.root.root {
  --focus-ring: none;

  :global(.p-dropdown-label) {
    padding-right: 0;
  }

  :global(.p-dropdown-label),
  :global(.p-dropdown-trigger-icon) {
    color: var(--color-text-white);
  }

  &.raisedTheme {
    :global(.p-dropdown) {
      border: none;
      box-shadow: 0 5px 7px -7px rgba(0, 0, 0, 0.7);
    }
  }

  &.outlinedTheme {
    :global(.p-dropdown) {
      border: 4px solid var(--color-light-grey);
      box-shadow: none;
    }
  }

  &.currentStatus {
    --surface-color: var(--color-file-status-current);
  }

  &.draftStatus {
    --surface-color: var(--color-file-status-draft);
  }

  &.archiveStatus {
    --surface-color: var(--color-file-status-archive);
    --text-color: var(--color-text-dark-grey);

    :global(.p-dropdown-label),
    :global(.p-dropdown-trigger-icon) {
      color: var(--text-color);
    }
  }
}
