@import "./src/styles/helpers/_index.scss";
.root.root {
  padding: 5px;
  width: auto;
  border-radius: 3px;

  :global(.p-button-icon) {
    font-size: 12px;
  }

  &:hover {
    background-color: var(--color-basic-white) !important;
  }
}
