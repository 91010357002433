@import "./src/styles/helpers/_index.scss";
.root.root.root {
  width: 54px;
  height: 54px;
  border: 4px solid #c4c4c4;
  border-radius: 50%;
  outline: none;
  background: white;

  &:hover {
    border-color: #c4c4c4;
    box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.57) !important;
  }

  &.noColor {
    background: repeating-conic-gradient(#fff 0 90deg, #c4c4c4 0 180deg) 0 0/20% 20%;
  }
}
