@import "./src/styles/helpers/_index.scss";
.root.root.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 30px;
  background-color: transparent;

  :global(.p-paginator-first),
  :global(.p-paginator-prev),
  :global(.p-paginator-next),
  :global(.p-paginator-last) {
    padding: 5px;
    min-width: auto;
    height: auto;
  }
}
