@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 60px;
  padding: 40px;
  border-radius: 16px;
  background-color: var(--color-basic-white);
}

.form {
  @mixin disabled {
    color: var(--color-grey);
    cursor: initial;
  }

  display: flex;
  flex-direction: column;
  gap: 24px;

  @include breakpoint-tablet {
    flex-direction: column;
  }

  .downloadTemplateButton {
    align-self: flex-end;
  }

  .options {
    width: 100%;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    @include breakpoint-tablet {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  }

  .label {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--color-text-grey);
    cursor: pointer;

    &Disabled {
      @include disabled;
    }
  }

  .option {
    :global(.p-dropdown-panel) {
      top: 36px !important;
      bottom: auto;
    }

    :global(.p-dropdown-items-wrapper) {
      max-width: 500px;
    }

    :global(.p-dropdown-item) {
      overflow: unset;
    }
  }

  .input {
    padding: 6px 20px;
  }

  .error {
    font-weight: 400;
    font-size: 10px;
    color: $purple;
    cursor: default;
  }

  .refreshIcon {
    position: absolute;
    top: 0;
    right: 10px;
    transform: rotate(90deg);

    &::before {
      color: var(--color-basic-dark);
      cursor: pointer;
    }

    &Disabled::before {
      @include disabled;
    }
  }
}

.saveButton {
  align-self: flex-end;
}
