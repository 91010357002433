@import "./src/styles/helpers/_index.scss";
.root {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;

  .header {
    td {
      padding: 5px 0;
      text-align: center;
      font-weight: 700;
      color: var(--color-text-grey);
    }
  }

  .gap {
    height: 10px;
  }
}
