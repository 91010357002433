@import "./src/styles/helpers/_index.scss";
.root.root.root.root {
  width: 100%;

  &.isMenuVisible {
    box-shadow: var(--focus-ring) !important;
  }

  &.isActive {
    background-color: $basic-white;
    font-weight: 500;

    :global(.p-multiselect-clear-icon) {
      color: var(--color-red);
      font-weight: bold;
    }
  }

  &.isInvalid {
    &:global(.p-multiselect) {
      border-color: var(--color-red);
    }
  }

  &:global(.p-multiselect-chip.p-multiselect-chip) {
    height: auto;
    line-height: 1rem;

    :global(.p-multiselect-label) {
      height: auto;
      min-height: 36px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 4px;
    }

    :global(.p-multiselect-token) {
      margin: 0;
      max-width: 100%;
      padding: 4px 8px;
      border-radius: 2rem;
      font-size: 10px;
      background-color: rgba(0, 0, 0, 0.07);

      :global(.p-multiselect-token-label) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    :global(.p-multiselect-token-icon) {
      transition: color 150ms;
      font-size: 0;

      &:before {
        content: '\e90b';
        font-size: 12px;
      }

      &:hover {
        color: var(--color-red);
      }
    }
  }

  &.darkTheme {
    --invalid-color: rgba(237, 85, 101, 0.25);
    background-color: var(--rdg-header-background-color);
    border-color: $basic-white;
    color: $basic-white;

    &.isInvalid {
      &:global(.p-multiselect) {
        background-color: var(--invalid-color);
        border-color: var(--invalid-color);
      }
    }

    :global(.p-multiselect-trigger-icon) {
      color: $basic-white !important;
    }

    :global(.p-multiselect-clear-icon) {
      color: $basic-white;

      &:hover {
        color: var(--color-red);
      }
    }
  }

  &.darkTheme.isActive {
    :global(.p-multiselect-trigger-icon) {
      color: $lazur;
    }
  }

  &.greyTheme {
    --focus-ring: none;
    background-color: var(--color-light-grey);
    border-color: var(--color-light-grey);
  }

  &.smallSize {
    height: 32px;

    :global(.p-multiselect-label) {
      height: 30px;
    }
  }

  &.mediumSize {
    height: 36px;

    :global(.p-multiselect-label) {
      height: 34px;
    }
  }

  :global(.p-multiselect-clear-icon) {
    right: 26px;
  }
}

.panelFooter {
  padding: 10px 16px;
  font-size: 12px;
}
