@import "./src/styles/helpers/_index.scss";
.root.root.root {
  --size-small: 20px;
  --size-medium: 50px;
  --size-large: 150px;
  --z-index: 1110;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: var(--z-index);
  transition: all 0.2s ease;

  &:not(.visible) {
    display: none;
    opacity: 0;
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.withBackground {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &.smallSize {
    :global(.p-progress-spinner) {
      width: var(--size-small);
      height: var(--size-small);
    }
  }

  &.mediumSize {
    :global(.p-progress-spinner) {
      width: var(--size-medium);
      height: var(--size-medium);
    }
  }

  &.largeSize {
    :global(.p-progress-spinner) {
      width: var(--size-large);
      height: var(--size-large);
    }
  }
}
