@import "./src/styles/helpers/_index.scss";
.Dashboard {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.DashboardLoader {
  margin: auto;
}

.CloseBtn.CloseBtn {
  margin: 5px;
  align-self: flex-end;
}

.Brands {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.Main {
  width: 100%;
  min-width: 385px;
  min-height: 600px;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 20px 0 70px;
}

.Header {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
}

.Title {
  color: $text-dark-blue;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 27px;
}

.FilterByBrandName {
  margin-left: auto;
  margin-right: 24px;
}

.AdditionalInfo {
  position: relative;
  display: flex;
  align-items: center;
  height: 14px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  color: $text-dark-blue;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 27.6px;
  color: $text-dark-blue;
}

.Show {
  &Value {
    margin-left: 5px;
    color: $text-grey;
  }
}

.PageSize {
  display: flex;
  align-items: center;

  &List {
    display: flex;
    padding: 0;
    list-style: none;
  }

  &Item {
    padding: 0 5px;
    color: $text-grey;
    cursor: pointer;

    &:hover,
    &Active {
      color: $text-active-link;
    }
  }
}

.YearSelector.YearSelector {
  margin-left: 50px;

  @media (max-width: 850px) {
    margin-left: 25px;
  }

  .YearButton.YearButton {
    &Active {
      border: 1px solid var(--border-color);
      color: var(--color-text-dark-blue);
      box-shadow: none;
    }
  }
}

.PaginationButtons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;

  @include breakpoint(1175px) {
    position: static;
    transform: translateX(0);
  }
}

.ButtonWrapper {
  min-width: 75px;
  display: inline-block;
}

.MainContent {
  flex: 1 1 100%;
}

.BrandsList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.CardWrapper {
  margin: 0 30px 30px 0;
  transition: box-shadow 300ms;
  border-radius: 15px;

  &:hover {
    box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.57) !important;
  }
}

.Card {
  text-decoration: none;
  cursor: pointer;
}

.Summary {
  flex: 0 0 360px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  max-width: 360px;
  overflow: hidden;

  padding: 20px;
  background: $basic-white;

  &Header {
    margin-top: 18px;

    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: $text-dark-blue;
  }

  &Chart {
    width: 320px;
    display: flex;
    align-items: center;
    margin-top: 18px;
  }

  &Info {
    margin-top: 18px;
  }

  &Label {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: $text-grey;

    &IsEmpty {
      align-self: flex-start;
    }
  }

  &Value {
    margin: 14px 0 28px;

    font-size: 18px;
    line-height: 21px;
  }

  &CustomButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .Button {
    margin: 5px auto;
  }
  
  .ButtonText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 170px;
  }  
}
