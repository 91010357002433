@import "./src/styles/helpers/_index.scss";
.root {
  margin: 0 0 0 var(--header-separator-indent);
  height: calc(100% - 8px);
  display: flex;
  flex: 1 1 auto;
  overflow-x: auto;
  grid-gap: var(--header-separator-indent);

  &::-webkit-scrollbar {
    height: 6px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    height: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0c2c6;
    border-radius: 3px;
    border: 3px solid transparent;
    cursor: pointer;
  }
}

.toolsButton {
  color: $text-grey;
  font-size: 14px;
  font-weight: 500;
  
  &:hover {
    color: $basic-white;
  }

  &Active {
    color: $text-active-link;
  }
}

.toolsMenu.toolsMenu.toolsMenu {
  z-index: 1020 !important;
  padding: 6px 0;
  background-color: $basic-dark;

  :global(.p-menuitem) {
    &:hover {
      background-color: $basic-dark-blue;

      :global(.p-menuitem-text) {
        color: $basic-white;
      }
    }
  }

  a[class*='navLink'][class*='navLink'] {
    padding: 10px 20px;
    width: auto;
  }
}

.navLink {
  display: flex;
  align-items: center;
  width: min-content;
  text-decoration: none;
  color: $text-grey;
  font-size: 14px;
  font-weight: 500;
  transition: color 200ms;

  &:hover {
    color: $text-white;
  }

  &Active {
    color: $text-active-link;
  }
}
