@import "./src/styles/helpers/_index.scss";
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: var(--color-primary);
  --toastify-color-info: var(--color-blue);
  --toastify-color-success: var(--color-green);
  --toastify-color-warning: var(--color-yellow);
  --toastify-color-error: var(--color-red);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 500px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 80px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Rubik, sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: var(--color-text-grey);
  --toastify-text-color-dark: #fff;
}

:global(.Toastify__toast) {
  cursor: unset;
}

:global(.Toastify--animate) {
  animation-duration: 0.2s;
}

.toastContainer {
  font-size: 14px;
}

.closeButton {
  align-self: flex-start;
  background-color: transparent !important;
}
