@import "./src/styles/helpers/_index.scss";
.root.root {
  padding: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  border-radius: 15px;
  background-color: var(--color-basic-white);
  font-size: 12px;

  .filter {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    .filterName {
      margin-bottom: 4px;
      color: var(--color-text-grey);
      font-size: 12px;
      font-weight: 500;
    }
  }

  .brandFilter {
    :global(.p-placeholder) {
      font-size: 12px;
    }
  }

  .periodFilter {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    .calendarInput {
      font-size: 12px;
    }
  }

  .selectCurrentMonthButton {
    margin-left: 10px;
    text-decoration: underline;
    font-weight: 500;
  }

  .label {
    cursor: pointer;
    color: var(--color-text-grey);
    font-weight: 500;
  }
}
