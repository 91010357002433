@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 5px;

  [class~='p-datepicker-title'] {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 140px 110px;
    grid-gap: 15px;
  }

  [class~='p-datepicker'] {
    border: none;

    [class~='p-timepicker'] {
      [class~='p-link'] {
        height: auto;
      }
    }
  }

  [class~='p-datepicker-header'] {
    border-bottom: none !important;
  }

  [class~='p-datepicker-group-container'] {
    height: 435px;
  }
}

.selectModes {
  padding: 0 22px;
  display: flex;
  grid-gap: 15px;
  justify-content: space-between;
}

.mode {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
}

.periodCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3f2fd;
}
