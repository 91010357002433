@import "./src/styles/helpers/_index.scss";
.root {
  display: inline-block;
  position: relative;
  color: var(--color-basic-white);
  font-size: 0;

  .link {
    display: block;
    color: var(--color-basic-white);
    text-decoration: none;
    font-size: 0;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    width: max-content;
  }

  .popup {
    --arrow-indent: 16px;
    --arrow-width: 12px;
    padding: 10px 16px 10px 16px;
    width: 320px;
    min-height: 80px;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    position: absolute;
    top: calc(100% + 18px);
    right: calc(50% - var(--arrow-indent));
    background-color: #465876;
    font-size: 14px;
    line-height: 1.3;
    border-radius: 6px;

    &:before {
      display: block;
      content: '';
      width: var(--arrow-width);
      height: 16px;
      position: absolute;
      bottom: 100%;
      right: calc(var(--arrow-indent) - var(--arrow-width) / 2);
      background-color: #465876;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }

    &.rightDirection {
      right: unset;
      left: calc(50% - var(--arrow-indent));

      &:before {
        right: unset;
        left: calc(var(--arrow-indent) - var(--arrow-width) / 2);
      }
    }

    .infoIcon {
      color: var(--color-yellow);
      font-size: 24px;
    }

    .message {
      flex-grow: 1;
    }

    .countdown {
      margin-left: 14px;
    }
  }
}
