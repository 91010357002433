@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 10px;
  width: 280px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  font-size: 12px;

  .label {
    margin-bottom: 4px;
    min-height: 28px;
    font-weight: 500;
    text-align: center;
  }

  .select {
    text-align: center;
  }
}
