@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    grid-gap: 15px;

    .button {
      font-size: 16px;

      :global(.p-button-icon) {
        font-weight: 700;
      }
    }
  }
}
