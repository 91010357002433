@mixin field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  white-space: nowrap;
  color: var(--color-basic-dark);
}

@mixin input {
  height: 36px;
  padding: 8.5px 16px;
  border: var(--border);
  border-radius: 18px;
  font-size: 13px;
  text-align: left;
  color: #495057;

  &:hover {
    border-color: var(--border-color);
    box-shadow: var(--focus-ring);
  }
}
