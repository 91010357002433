@import "./src/styles/helpers/_index.scss";
.root {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 30px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 27.5px;
  color: $text-dark-blue;
}

.content {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, max-content);
  grid-gap: 30px 60px;
  justify-content: left;
  padding: 60px;
  background-color: $basic-white;
  border-radius: 15px;
}

.upload {
  position: relative;
}
