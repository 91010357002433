@import "./src/styles/helpers/_index.scss";
.UploadStatistics {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.Uploader {
  display: flex;
  height: 300px;
  margin-top: 50px;
  padding: 20px;
  border-radius: 15px;
  font-size: 20px;
  background-color: $basic-white;
}

.Files {
  display: flex;
  margin-top: 50px;
  border-radius: 15px;
  background-color: $basic-white;
}

.FiltersColumn {
  width: 25%;
  padding: 20px;
  border-right: 1px solid $basic-light-grey;
}

.FilesColumn {
  width: 75%;
  padding: 20px;
}

.FilesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.FilesHead {
  margin-top: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $text-grey;
}

.FilesTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 7px;
  font-size: 12px;

  td {
    padding: 5px;
    text-align: center;
    color: $basic-dark;
    word-break: break-word;

    &:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.FilesTableRow {
  background-color: $basic-light-grey;
}

.FileName {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 10px;
  font-weight: 500;
  white-space: nowrap;
  background-color: $basic-light-grey;
}

.FileActions {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin: 0 auto;
}

.FileAction {
  position: relative;
  margin: 0 5px;
  font-size: 16px;
  text-decoration: none;
  color: $basic-dark;
  cursor: pointer;

  &Download {
    font-size: 18px;
  }
}

.TableHeader {
  padding: 5px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $text-grey;
  user-select: none;

  &Active {
    color: $text-active-link;
  }

  &[data-sort] {
    cursor: pointer;
  }
}

.SortingIcon {
  display: inline-block;
  padding: 0px 5px;

  &::before {
    padding-top: 2px;
  }

  &Reverse {
    transform: rotate(180deg) translateY(2px);
  }
}

.StatusIndicators {
  margin: auto;
}
