@import "./src/styles/helpers/_index.scss";
.root {
  padding: 0 10px;
  width: max-content;
  height: 14px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: left;

  &.updatable {
    width: 60px;
  }

  .status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .successStatus {
    background-color: var(--color-green);
  }

  .inprogressStatus {
    background-color: var(--color-yellow);
  }

  .errorStatus {
    background-color: var(--color-red);
  }

  .updateButton {
    font-size: 14px;
    color: var(--color-basic-dark);
  }
}
