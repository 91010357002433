@import "./src/styles/helpers/_index.scss";
.root {
  padding: 25px 25px 25px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: var(--color-basic-white);

  .header {
    padding: 0 80px 16px 0;
    display: flex;

    .title {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      color: var(--color-text-grey);
    }
  }

  .content {
    height: 0;
    padding-right: 10px;
    flex: 1 1 auto;
    line-height: 1.5rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 15px;
    text-align: justify;
  }
}
