@import "./src/styles/helpers/_index.scss";
.FallbackComponent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &Message {
    display: block;
    text-align: center;
    font-size: 19px;
    color: $text-grey;
  }
}
