@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  font-size: 12px;
  user-select: none;

  &Admin {
    cursor: pointer;
  }
}

.menu.menu.menu {
  padding: 6px 0;
  background-color: $basic-dark;
  border-radius: 0 0 3px 3px;

  :global(.p-menuitem), :global(.p-menuitem-link) {
    &:hover {
      background-color: $basic-dark-blue;

      :global(.p-menuitem-text),
      :global(.p-menuitem-icon) {
        color: $basic-white;
      }
    }
  }
  
  :global(.p-menuitem-link) {
    :global(.p-menuitem-text),
    :global(.p-menuitem-icon) {
      color: rgb(240, 243, 248);
      font-size: 14px;
    }
  }
}

.linkToAdministration {
  padding: 12px 16px;
  width: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(240, 243, 248);
  font-size: 14px;

  .linkToAdministrationIcon {
    margin-right: 0.5rem;
    font-size: 14px;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  color: $text-white;
  grid-gap: 4px;
}

.name {
  min-width: min-content;
  max-width: 200px;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 500;
}

.logoutButton.logoutButton.logoutButton.logoutButton {
  color: $text-white;
  width: 20px;
  height: 20px;
  position: relative;

  &:hover {
    color: $lazur;
  }
}