@import "./src/styles/helpers/_index.scss";
.AuthBackground {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  background: url('assets/background.svg') no-repeat 50% fixed;
  background-size: cover;
}

.Form {
  &Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 370px;
    min-height: 300px;
    padding: 30px 60px;
    border-radius: 15px;

    color: $text-white;
    background: linear-gradient(180deg, #182435, rgba(24, 36, 53, 0.46));
    opacity: 0.9;
  }

  &Title {
    margin-top: 15px;
    margin-bottom: 40px;

    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &Label {
    display: block;
    margin-top: 25px;
    margin-bottom: 5px;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &ErrorLabel {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: $red;
  }

  &InputGroup {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $basic-grey;

    color: #838383;

    &Empty {
      border-bottom: 2px solid $red;
    }
  }

  &Input {
    display: block;
    width: 100%;
    height: 36px;
    padding: 2px 15px;
    border: none;
    box-sizing: border-box;

    font-size: 12px;
    line-height: 36px;
    color: $text-white;
    background-color: transparent;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover {
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      caret-color: #fff;
    }
  }

  &ForgotPasswordLink {
    margin: 5px 0;

    text-align: right;

    > a {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-decoration: none;
      color: $text-white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.Button.Button {
  display: inline-block;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 10px;
  border: 2px solid $basic-grey;
  text-align: center;
}

.Footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .SkLink {
    margin: 10px;
  }

  .SkLogo {
    height: auto;
    width: 200px;
  }
}
