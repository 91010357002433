@import "./src/styles/helpers/_index.scss";
.root {
  width: 100%;
  height: 100%;
  padding: 80px 10px 10px 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  color: #8d8d8d;
}

.header {
  font-size: 9rem;
}

.title {
  font-size: 18px;
}
