@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  align-items: flex-start;
  grid-gap: 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-dark-blue);

  .options {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: 0 8px;

    .option {
      display: flex;
      align-items: flex-start;

      &.active {
        .label {
          color: var(--color-text-active-link);
          text-decoration: none;
        }

        :global(.p-checkbox-icon) {
          color: var(--color-text-active-link);
        }
      }
    }
  }

  .label {
    user-select: none;
    color: var(--color-text-grey);
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
  }

  :global(.p-checkbox-box.p-checkbox-box.p-checkbox-box) {
    &,
    &:hover,
    &:global(.p-highlight),
    &:global(.p-highlight):hover {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

  :global(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
    color: transparent;
  }
}
