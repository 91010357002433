@import "./src/styles/helpers/_index.scss";
.Filters {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  overflow: auto;
  width: 100%;
  height: 170px;
  background-color: $basic-white;

  @include breakpoint(1600px) {
    height: 230px;
  }
}

.Filter {
  :global(.p-component.p-dropdown .p-dropdown-label) {
    font-size: 14px;
    font-weight: 500;
  }
}

.LogoWrapper {
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  max-width: 350px;
  min-height: 100px;
  padding: 10px 20px;
  background-color: #e4dcd3;
}

.Logo {
  width: 100%;
  min-width: 160px;
  height: 50px;
}

.FiltersWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
}

.SelectsWrapper {
  display: flex;
}

.SelectsList {
  display: flex;
  justify-content: center;

  @include breakpoint(1600px) {
    flex-direction: column;
  }
}

.SelectsGroup {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &Periods {
    .Select {
      margin-top: 10px;
      min-width: 255px;
    }
  }
}

.Select {
  min-width: 120px;
  max-width: 200px;
  margin-left: 15px;
}

.Label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #0b2a6b;
}

.DashboardName {
  align-self: self-start;
  margin-top: 5px;
  margin-left: 15px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #0b2a6b;

  @include breakpoint(1300px) {
    font-size: 20px;
  }
}

.ApplyButton {
  align-self: flex-end;
  width: 120px;
  height: 42px;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 16px;
}
