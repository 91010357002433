@import "./src/styles/helpers/_index.scss";
.root {
  flex-grow: 1;
  padding: 0 5px;
  display: grid;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-gap: 5px;
  grid-auto-flow: column;

  &:after {
    display: block;
    content: '';
  }
}

.badge:global(.p-badge) {
  width: 10px;
  height: 10px;
  justify-self: flex-start;
}
