@import "./src/styles/helpers/_index.scss";
.titleLink {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  flex-grow: 1;
  transition: 300ms;

  &:hover {
    transform: translateX(2px);
  }
}

.slash {
  display: inline-block;
  margin: 0 10px;
}
