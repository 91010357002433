@import "./src/styles/helpers/_index.scss";
.root {
  flex: 0 0 auto;
  height: var(--footer-height);
  margin-top: auto;

  .content {
    display: flex;
    font-size: 12px;
    line-height: 50px;
    text-align: center;
    color: $text-grey;

    &.colorLight {
      color: $text-white;
    }

    .year {
      margin-right: 5px;
    }

    .version {
      margin-left: 5px;
    }
  }
}