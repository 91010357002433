@import "./src/styles/helpers/_index.scss";
.Root {
  border-radius: 15px;
  background-color: $basic-white;
  font-size: 12px;
}

.Label {
  margin-top: 35px;
  margin-bottom: 15px;
  color: $text-grey;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &:first-child {
    margin-top: 0;
  }
}

.Search {
  &Name {
    width: 100%;
    padding: 0 40px 0 15px;
    height: 33px;
    font-size: 12px;
    line-height: 14px;
    color: $basic-dark;
    border: none;
    border-radius: 15px;
    background: #f4f4f4;
    outline: none;
  }

  &Container {
    position: relative;
  }

  &Button {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $basic-dark;
    cursor: pointer;
    font-size: 16px;

    &Disabled {
      color: grey;
      cursor: default;
    }
  }
}

.cabinetFilter {
  :global(.p-placeholder) {
    font-size: 12px;
  }
}

.period {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.periodLabel {
  color: var(--color-text-grey);
  font-weight: 500;
}
