@import "./src/styles/helpers/_index.scss";
.root.root {
  width: 100%;

  :global(.p-multiselect-label) {
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.tooltip.tooltip {
  :global(.p-tooltip-text) {
    padding: 5px 8px;
    background-color: $basic-white;
    color: #1a2534;
    font-size: 13px;
  }

  :global(.p-tooltip-arrow) {
    display: none;
  }
}
