$iconfont-font: "iconfont";

@font-face {
    font-family: $iconfont-font;
    src: url("./fonts/iconfont/iconfont.eot?83653e79f3ab8e49c22b5ee546be3b6c#iefix") format("embedded-opentype"),
url("./fonts/iconfont/iconfont.woff2?83653e79f3ab8e49c22b5ee546be3b6c") format("woff2"),
url("./fonts/iconfont/iconfont.woff?83653e79f3ab8e49c22b5ee546be3b6c") format("woff");
}

*[class^="icon-"]:before, *[class*=" icon-"]:before {
    font-family: iconfont !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$iconfont-map: (
    "arrow-down": "\f101",
    "arrow-left": "\f102",
    "arrow-right": "\f103",
    "arrow-to-first": "\f104",
    "arrow-to-last": "\f105",
    "cabinet": "\f106",
    "check": "\f107",
    "compare": "\f108",
    "cross": "\f109",
    "delete": "\f10a",
    "download-bold": "\f10b",
    "download": "\f10c",
    "edit": "\f10d",
    "error": "\f10e",
    "export": "\f10f",
    "flash": "\f110",
    "globe": "\f111",
    "load": "\f112",
    "lock": "\f113",
    "menu": "\f114",
    "paper": "\f115",
    "pause": "\f116",
    "play": "\f117",
    "question": "\f118",
    "radio": "\f119",
    "replace": "\f11a",
    "reset": "\f11b",
    "save": "\f11c",
    "search": "\f11d",
    "sent": "\f11e",
    "spinner": "\f11f",
    "star": "\f120",
    "success": "\f121",
    "telegram": "\f122",
    "template": "\f123",
    "tv": "\f124",
    "tv2": "\f125",
    "user": "\f126",
    "warning": "\f127",
);

.icon-arrow-down:before {
    content: map-get($iconfont-map, "arrow-down");
}
.icon-arrow-left:before {
    content: map-get($iconfont-map, "arrow-left");
}
.icon-arrow-right:before {
    content: map-get($iconfont-map, "arrow-right");
}
.icon-arrow-to-first:before {
    content: map-get($iconfont-map, "arrow-to-first");
}
.icon-arrow-to-last:before {
    content: map-get($iconfont-map, "arrow-to-last");
}
.icon-cabinet:before {
    content: map-get($iconfont-map, "cabinet");
}
.icon-check:before {
    content: map-get($iconfont-map, "check");
}
.icon-compare:before {
    content: map-get($iconfont-map, "compare");
}
.icon-cross:before {
    content: map-get($iconfont-map, "cross");
}
.icon-delete:before {
    content: map-get($iconfont-map, "delete");
}
.icon-download-bold:before {
    content: map-get($iconfont-map, "download-bold");
}
.icon-download:before {
    content: map-get($iconfont-map, "download");
}
.icon-edit:before {
    content: map-get($iconfont-map, "edit");
}
.icon-error:before {
    content: map-get($iconfont-map, "error");
}
.icon-export:before {
    content: map-get($iconfont-map, "export");
}
.icon-flash:before {
    content: map-get($iconfont-map, "flash");
}
.icon-globe:before {
    content: map-get($iconfont-map, "globe");
}
.icon-load:before {
    content: map-get($iconfont-map, "load");
}
.icon-lock:before {
    content: map-get($iconfont-map, "lock");
}
.icon-menu:before {
    content: map-get($iconfont-map, "menu");
}
.icon-paper:before {
    content: map-get($iconfont-map, "paper");
}
.icon-pause:before {
    content: map-get($iconfont-map, "pause");
}
.icon-play:before {
    content: map-get($iconfont-map, "play");
}
.icon-question:before {
    content: map-get($iconfont-map, "question");
}
.icon-radio:before {
    content: map-get($iconfont-map, "radio");
}
.icon-replace:before {
    content: map-get($iconfont-map, "replace");
}
.icon-reset:before {
    content: map-get($iconfont-map, "reset");
}
.icon-save:before {
    content: map-get($iconfont-map, "save");
}
.icon-search:before {
    content: map-get($iconfont-map, "search");
}
.icon-sent:before {
    content: map-get($iconfont-map, "sent");
}
.icon-spinner:before {
    content: map-get($iconfont-map, "spinner");
}
.icon-star:before {
    content: map-get($iconfont-map, "star");
}
.icon-success:before {
    content: map-get($iconfont-map, "success");
}
.icon-telegram:before {
    content: map-get($iconfont-map, "telegram");
}
.icon-template:before {
    content: map-get($iconfont-map, "template");
}
.icon-tv:before {
    content: map-get($iconfont-map, "tv");
}
.icon-tv2:before {
    content: map-get($iconfont-map, "tv2");
}
.icon-user:before {
    content: map-get($iconfont-map, "user");
}
.icon-warning:before {
    content: map-get($iconfont-map, "warning");
}
