@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
 
  .reloadPageSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
    color: var(--color-text-dark-grey);
  }

  .actions {
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: end;
    grid-gap: 10px;
    grid-auto-flow: column;
    flex-grow: 1;
  }

  button:global(.p-button-icon-only) :global(.p-button-icon.pi-play) {
    padding-left: 2px;
  }
}

.confirmPopup.confirmPopup {
  max-width: 400px;
}
