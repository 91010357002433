@import "./src/styles/helpers/_index.scss";
.root.root {
  width: 400px;

  :global(.p-dialog-header) {
    padding: 4px;
  }

  :global(.p-dialog-content) {
    padding: 1rem 1.5rem 1.5rem;

    label {
      display: block;
      margin-top: 40px;
    }

    div {
      margin-top: 6px;
      margin-bottom: 10px;
    }
  }

  .footer {
    justify-content: space-between;
  }
}
