@import "./src/styles/helpers/_index.scss";
.Layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 600px;
}

.Content {
  min-width: 600px;
  flex: 1 0 auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: $text-dark-blue;
}

.Advertiser {
  margin: 30px 0 30px 20px;
}

.Logo {
  max-height: 55px;

  &Hide {
    visibility: hidden;
  }
}

.Form {
  padding: 20px 30px 30px;
  border-radius: 15px;

  background-color: $basic-white;
}

.Tabs {
  width: 100%;

  :global(.p-tabview) {
    :global(.p-tabview-nav-container) {
      padding: 0 20px 10px;
    }

    :global(.p-tabview-nav) {
      border-color: transparent;
    }
  }
}

.CustomSettings {
  margin: 0;
  margin-bottom: 20px;
}

.Save {
  display: flex;
  justify-content: center;
}
