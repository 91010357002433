@import "./src/styles/helpers/_index.scss";
.BudgetChart {
  margin: 0 auto;
}

.Pie {
  fill: transparent;
  stroke: $basic-grey;

  &Active {
    margin: 0;

    .Cell {
      opacity: 0.4;
    }
  }
}

.Cell {
  opacity: 1;

  &Active {
    opacity: 1;
  }
}

.YearLabel {
  font-size: 32px;
  opacity: 1;
  fill: $text-middle-grey;
  stroke: none;
}

.Tooltip {
  padding: 7px;
  min-width: 100px;
  max-width: 160px;

  font-size: 12px;
  line-height: 1.5;

  background: rgba(100%, 100%, 100%, 0.7);
  border: 1px solid currentColor;

  &Header {
    width: 100%;

    &::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 5px;

      border-radius: 50%;
      background: currentColor;
    }
  }

  &Brand {
    margin: 0;
    font-weight: 500;
    color: $text-black;
  }

  &Sum {
    margin: 0;
    color: $text-black;
  }
}
