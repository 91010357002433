@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
}

.time {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}