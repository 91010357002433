@import "./src/styles/helpers/_index.scss";
.root.root.root.root.root {
  padding: 0;
  width: auto;
  color: var(--color-text-grey);
  border: none;
  border-radius: 0;

  &,
  &:hover {
    background: none;
    box-shadow: none;
  }

  :global(.p-button-label) {
    display: none;
  }

  :global(.p-button-icon) {
    line-height: 0;
  }

  &.mediumSize {
    :global(.p-button-icon:before) {
      font-size: 20px;
    }
  }

  &.largeSize {
    :global(.p-button-icon:before) {
      font-size: 28px;
    }
  }

  &.checked {
    background-image: linear-gradient(to bottom right, #ffa000 0%, #ffee58 50%, #ffa000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
