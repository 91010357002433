@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  opacity: 0.5;
  background-color: var(--color-light-grey);

  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 220px;
    margin: 40px;
    border: 1px solid var(--color-text-grey);
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--color-text-grey);
    background-color: var(--color-light-grey);

    &.smallSize {
      flex-direction: row;
      justify-content: space-around;
      height: 40px;
      margin: 10px;
      border: none;
    }
  }

  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
    z-index: 1;
    color: var(--color-primary);
    cursor: pointer;
    font-size: 18px;
  }

  .downloadIcon {
    font-size: 34px;
  }

  .label {
    margin: 10px 0;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    white-space: break-spaces;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
}
