@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  display: block;
  position: relative;
  border-radius: 15px;
  font-size: 14px;

  .label {
    margin-bottom: 6px;
    padding-left: 16px;
    display: inline-block;
    font-weight: 500;
  }

  .dropdown {
    text-align: center;
  }
}
