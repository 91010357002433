@import "./src/styles/helpers/_index.scss";
.root.root.root {
  --border-color: transparent;
  --focus-ring: none;

  .options {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;

    & > :global(.p-button) {
      padding: 10px 15px;
      opacity: 0.7;
      transition: opacity 150ms;

      &:hover {
        opacity: 1;
      }

      &:global(.p-highlight),
      &:global(.p-highlight):hover {
        opacity: 1;
        background-color: var(--color-light-grey);
      }
    }
  }

  .option {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;

    .name {
      margin-right: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: var(--color-basic-dark);
      flex-shrink: 1;
    }

    .button {
      flex-shrink: 0;

      :global(.p-button-icon) {
        font-size: 11px;
        opacity: 0.5;
        transition: opacity 150ms;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
