@import "./src/styles/helpers/_index.scss";
.IframeContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  &FullHeight {
    padding-bottom: calc(100vh - #{$header-height} - 42px);
  }
}

.Iframe {
  width: 100%;
  position: absolute;
  height: 100%;
  border: none;
  top: 0;

  &Hidden {
    display: none;
  }
}

.NoContent {
  padding: 50px 0;
  text-align: center;
  color: $text-grey;
  
  &FullHeight {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Loader {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
}
