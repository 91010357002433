@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 50px 5% 0;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-light-blue);
  grid-gap: 45px;

  .top {
    display: flex;
    grid-gap: 30px;

    .info {
      min-width: 600px;
      flex: 1 1 auto;
    }

    .description {
      min-width: 350px;
      max-width: 500px;
      flex: 1 10 auto;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    .footer {
      margin-top: auto;
    }
  }
}
