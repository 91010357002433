.swal-modal {
  --color: #495057;

  width: fit-content;
  min-width: 340px;
  max-width: 600px;
  padding: 20px;
  border-radius: 15px;

  .swal-title {
    margin-top: 0;
    font-size: 16px;
    color: var(--color);
  }

  .swal-text {
    color: var(--color);
  }

  .swal-content {
    input {
      display: block;
      width: 100%;
      margin: 0;
      padding: 2px 15px;
      box-sizing: border-box;
      border: none;
      outline: none;
      border-bottom: 1px solid $basic-grey;
      overflow: hidden;
      text-align: left;
      color: $basic-dark;
      font: normal 12px/36px Rubik, sans-serif;

      &:invalid {
        box-shadow: none;
        border-bottom-color: $purple;
      }
    }
  }

  .swal-footer {
    display: flex;
    justify-content: space-around;
  }

  .swal-button--confirm_removal {
    background-color: $purple !important;
    border-color: $purple !important;
  }

  &.swal-modal_invalid {
    @extend .swal-modal;

    .swal-content {
      input:not([type='file']) {
        animation: fadeInUp 3s;
      }

      &:after {
        content: 'Поле не должно быть пустым';
        position: absolute;
        left: 0;
        width: 100%;
        font-size: 10px;
        color: $red;
        opacity: 0;
        animation: fadeInUp 4s;
      }
    }
  }
}

.swal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInUp {
  from {
    opacity: 1;
    border-bottom-color: $red;
  }
  to {
    border-bottom-color: $basic-grey;
  }
}
