@import "./src/styles/helpers/_index.scss";
.root {
  flex-grow: 1;
  padding: 0 5px;
  display: grid;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-gap: 5px;
  grid-auto-flow: column;

  &:not(.withBadge) {
    justify-content: center;
  }

  &.withBadge {
    &:after {
      display: block;
      content: '';
    }
  }
}

.badge {
  justify-self: flex-start;
}

.dash {
  text-align: center;
  justify-self: center !important;
  width: 100%;
}
