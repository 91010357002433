@import "./src/styles/helpers/_index.scss";
.Subbrand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 15px;

  &:nth-child(odd) {
    background: hsla(0, 0%, 93.3%, 0.7);
  }
}

.RowNumber {
  flex: 0 0 auto;
  margin-right: 30px;
}

.SubbrandName {
  flex: 1 1 auto;
  margin-right: 30px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.Icons {
  flex: 0 0 auto;
  display: flex;
}

.Icon {
  padding-left: 8px;
  padding-right: 8px;

  color: rgb(134, 140, 149);
  cursor: pointer;

  &:hover {
    color: $basic-dark;
  }

  &Danger:hover {
    color: $purple;
  }
}

.Subbrands {
  position: relative;
  margin: 0;

  &List {
    width: 100%;
    min-height: 150px;
    padding: 0;

    list-style: none;
    font-size: 13px;
    color: $basic-dark;
  }
}

.SubTitle {
  font-size: 20px;
  font-weight: 500;
  color: $text-dark-blue;
}
