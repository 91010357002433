@import "./src/styles/helpers/_index.scss";
.root {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-auto-columns: 220px;
}

.selectionButton {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  --focus-ring: var(--focus-ring-dark);

  & [class~='p-disabled'] {
    opacity: 1;
  }

  :global(.p-button) {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
}
