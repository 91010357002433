@import "./src/styles/helpers/_index.scss";
.root.root.root.root.root {
  width: 16px;
  height: 16px;
  background-color: var(--color-primary);
  color: var(--color-text-white);
  border-radius: 50%;

  :global(.p-button-label) {
    display: none;
  }

  :global(.p-button-icon) {
    font-size: 8px;
    font-weight: 700;
    width: 8px;
    height: 8px;
  }
}
