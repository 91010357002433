@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
    }
  }

  .fixation {
    margin-left: auto;
    margin-right: 10%;
  }

  .files {
    width: 90%;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}
