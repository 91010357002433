@import "./src/styles/helpers/_index.scss";
.root {
  max-width: 100%;
  max-height: 100%;
  text-align: inherit;
  flex-grow: 1;
  white-space: break-spaces;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
