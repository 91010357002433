:root {
  --font-family: Rubik, Arial, BlinkMacSystemFont, Segoe UI;
}

.p-component {
  font-family: var(--font-family);
  font-size: 0.875rem;

  &,
  * {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      height: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      border: 3px solid transparent;
      cursor: pointer;
    }
  }
}

.p-link {
  font-family: var(--font-family);
}
.p-link:focus {
  box-shadow: var(--focus-ring);
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: var(--color-blue);
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: var(--font-family);
}

.p-datepicker {
  border: var(--border);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: var(--focus-ring);
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}
.p-datepicker .p-datepicker-header .p-dropdown-label {
  padding-top: 7px;
  padding-bottom: 5px;
}
.p-datepicker table td > span:focus {
  box-shadow: var(--focus-ring-blue);
}
.p-datepicker .p-timepicker button:focus {
  box-shadow: var(--focus-ring);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-datepicker-trigger.p-datepicker-trigger.p-button {
  padding-right: 3px;
}
.p-datepicker.p-connected-overlay-enter-done.p-connected-overlay-enter-done {
  border-radius: 6px;
}
.p-datepicker-title.p-datepicker-title {
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  grid-gap: 12px;
}
.p-datepicker.p-datepicker-small {
  padding: 0.4rem;

  .p-datepicker-calendar-container {
    height: 245px;
  }

  .p-datepicker-header {
    padding: 0.4rem;
  }
  .p-datepicker-header .p-datepicker-prev,
  .p-datepicker-header .p-datepicker-next {
    width: 1.6rem;
    height: 1.6rem;
  }
  .p-datepicker-header .p-datepicker-title {
    line-height: 1.6rem;
  }
  .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.4rem;
  }
  table {
    font-size: 0.8rem;
    margin: 0.4rem 0;
  }
  table th {
    padding: 0.4rem;
  }
  table th > span {
    width: 2rem;
    height: 2rem;
  }
  table td {
    padding: 0.12rem;
  }
  table td > span {
    width: 2rem;
    height: 2rem;
  }
  .p-datepicker-buttonbar {
    padding: 0.8rem 0;
  }
  .p-timepicker {
    padding: 0.4rem;
  }
  .p-timepicker button {
    width: 1.6rem;
    height: 1.6rem;
  }
  .p-timepicker span {
    font-size: 1.45rem;
  }
  .p-timepicker > div {
    padding: 0 0.4rem;
  }
  .p-monthpicker {
    margin: 0.4rem 0;
  }
  .p-monthpicker .p-monthpicker-month {
    padding: 0.4rem;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
  .p-datepicker-next-icon,
  .p-datepicker-prev-icon {
    font-size: 0.85rem;
    font-weight: bold;
  }
}

.p-cascadeselect {
  border: var(--border);
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: var(--color-blue);
}
.p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}

.p-checkbox {
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid var(--checkbox-border-color);
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--color-blue);
  background: var(--color-blue);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--color-blue);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: var(--focus-checkbox-shadow);
  border-color: var(--color-blue);
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-blue);
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: var(--color-blue);
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: var(--font-family);
}

.p-dropdown {
  border: var(--border);
  border-radius: 2rem;
  font-size: 0.875rem;

  .p-dropdown-clear-icon {
    margin: 0;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--color-red);
    right: 1.9rem;
    transform: translateY(-50%);
  }
}
.p-dropdown:not(.p-disabled):hover {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-dropdown .p-dropdown-label {
  font-size: 0.875rem;
}

.p-dropdown-panel {
  margin-top: 5px;
  font-size: 12px;

  .p-dropdown-item {
    line-height: 1;
  }
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--color-blue);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--color-blue);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--color-blue);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--color-blue);
}

.p-inputgroup-addon {
  border-top: var(--border);
  border-left: var(--border);
  border-bottom: var(--border);
}
.p-inputgroup-addon:last-child {
  border-right: var(--border);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: var(--focus-ring);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-blue);
}

.p-inputtext {
  font-family: var(--font-family);
  padding: 8.5px 1rem;
  border: var(--border);
  border-radius: 18px;
}
.p-inputtext:enabled:hover {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-inputtext:enabled:focus {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}

.p-listbox {
  border: var(--border);
}

.p-multiselect {
  border: var(--border);
  border-radius: 18px;
  border-color: var(--border-color);
}
.p-multiselect:not(.p-disabled):hover {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-multiselect:not(.p-disabled).p-focus:hover {
  box-shadow: var(--focus-ring);
  border-color: var(--border-color);
}
.p-multiselect .p-multiselect-label {
  padding: 8.5px 1rem;
}
.p-multiselect .p-multiselect-trigger {
  width: 2.2rem;
}
.p-multiselect .p-multiselect-clear-icon {
  right: 2.2rem;
  top: calc(50% + 0.15rem);
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    color: var(--color-red);
  }
}

.p-multiselect-panel {
  margin-top: 5px;
  border-radius: 5px;
}
.p-multiselect-panel .p-multiselect-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  padding-right: 2rem;
  border-radius: 15px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  display: none;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  box-shadow: var(--focus-ring);
}
.p-multiselect-panel .p-multiselect-items {
  font-size: 12px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: var(--row-selected-background-color);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:not(.p-disabled):hover {
  color: #495057;
  background: var(--row-selected-background-color);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;

  & .p-checkbox-box {
    box-shadow: var(--focus-checkbox-shadow);
  }
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: var(--color-red);
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: var(--color-yellow);
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: var(--color-green);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--color-blue);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--color-blue);
  background: var(--color-blue);
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--color-blue);
}

.p-rating .p-rating-icon:focus {
  box-shadow: var(--focus-ring);
}
.p-rating .p-rating-icon.pi-star {
  color: var(--color-blue);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: var(--color-blue);
}

.p-selectbutton.p-buttonset.p-component .p-button {
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 18px;

  &.p-button:not(:first-of-type):not(:last-of-type),
  &.p-button:first-of-type,
  &.p-button:last-of-type {
    border-radius: 18px;
  }
}
.p-selectbutton.p-buttonset.p-component .p-button:focus {
  box-shadow: var(--focus-ring);
}
.p-selectbutton.p-buttonset.p-component .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton.p-buttonset.p-component .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: transparent;
  border-color: transparent;
  box-shadow: var(--focus-ring);
}
.p-selectbutton.p-buttonset.p-component .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton.p-buttonset.p-component .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton.p-buttonset.p-component .p-button.p-highlight {
  background: #fff;
  border-color: var(--border-color);
}
.p-selectbutton.p-buttonset.p-component .p-button.p-highlight:hover {
  background: #fff;
  border-color: var(--border-color);
  box-shadow: var(--focus-ring);
}
.p-selectbutton.p-buttonset.p-component .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton.p-buttonset.p-component .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-buttonset.p-component.p-invalid > .p-button {
  border-color: #f44336;
}

.p-slider .p-slider-handle {
  border: 2px solid var(--color-blue);
}
.p-slider .p-slider-handle:focus {
  box-shadow: var(--focus-ring);
}
.p-slider .p-slider-range {
  background: var(--color-blue);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--color-blue);
  border-color: var(--color-blue);
}

.p-treeselect {
  border: var(--border);
}
.p-treeselect:not(.p-disabled):hover {
  border-color: var(--color-blue);
}
.p-treeselect:not(.p-disabled).p-focus {
  box-shadow: var(--focus-ring);
  border-color: var(--color-blue);
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  box-shadow: var(--focus-ring);
}

.p-togglebutton.p-button {
  border: var(--border);
}
.p-togglebutton.p-button.p-highlight {
  background: var(--color-blue);
  border-color: var(--color-blue);
}

.p-button {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-button-text);
  padding: 8.5px 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 18px;
}
.p-button:not(.p-disabled):not(.p-button-text):hover {
  box-shadow: var(--focus-ring);
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
  color: var(--color-button-text);
}
.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
  background: var(--color-primary-active);
  border-color: var(--color-primary-active);
  color: var(--color-button-text);
}
.p-button.p-button-outlined {
  color: var(--color-primary);
}
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-primary-hover);
}
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: var(--color-primary-active);
}
.p-button.p-button-text {
  color: var(--color-primary);
}
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-primary-hover);
}
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: var(--color-primary-active);
}
.p-button:focus {
  box-shadow: var(--focus-ring);
}
.p-button .p-badge {
  color: var(--color-primary);
}

.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-button.p-button-info:enabled:focus,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-info:enabled:focus,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button,
.p-fileupload-choose.p-button-success {
  background: var(--color-green);
  border: 1px solid var(--color-green);
}
.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-green-hover);
  border-color: var(--color-green-hover);
}
.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  background: var(--color-green-active);
  border-color: var(--color-green-active);
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
  color: var(--color-green);
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-green);
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: var(--color-green);
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
  color: var(--color-green);
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-green);
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: var(--color-green);
}

.p-button.p-button-help:enabled:focus,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-help:enabled:focus,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
  background: var(--color-red);
  border: 1px solid var(--color-red);
}
.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-red-hover);
  border-color: var(--color-red-hover);
}
.p-button.p-button-danger:enabled:focus,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-danger:enabled:focus,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  background: var(--color-red-active);
  border-color: var(--color-red-active);
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: var(--color-red);
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-red);
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: var(--color-red);
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
  color: var(--color-red);
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-red);
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: var(--color-red);
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}
.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:enabled:hover,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  background: var(--color-secondary-hover);
  border-color: var(--color-secondary-hover);
}
.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}
.p-button.p-button-secondary:enabled:active,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:enabled:active,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
  background: var(--color-secondary-active);
  border-color: var(--color-secondary-active);
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
  background-color: transparent;
  color: var(--color-secondary);
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-secondary);
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  color: var(--color-secondary);
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
  color: var(--color-secondary);
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
  color: var(--color-secondary);
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
  color: var(--color-secondary);
}

.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  box-shadow: var(--focus-ring);
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: var(--color-blue);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--color-blue);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #e9ecef;
  color: var(--color-blue);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: var(--color-blue);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  box-shadow: var(--focus-ring);
}
.p-datatable .p-column-resizer-helper {
  background: var(--color-blue);
}

.fc.fc-unthemed .fc-toolbar .fc-button {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  border: var(--border);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--color-blue);
  border-color: var(--color-blue);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  box-shadow: var(--focus-ring);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  border: var(--border);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--color-blue);
  border-color: var(--color-blue);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  box-shadow: var(--focus-ring);
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  box-shadow: var(--focus-ring);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  box-shadow: var(--focus-ring);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: var(--focus-ring);
}

.p-treetable .p-sortable-column.p-highlight {
  color: var(--color-blue);
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--color-blue);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  box-shadow: var(--focus-ring);
}
.p-treetable .p-column-resizer-helper {
  background: var(--color-blue);
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid var(--color-blue);
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: var(--focus-ring);
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  box-shadow: var(--focus-ring);
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  box-shadow: var(--focus-ring);
}

.p-tabview .p-tabview-nav {
  --focus-ring: none;
  background-color: transparent;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 6px 12px;
  background-color: transparent;
  border-color: transparent;
  font-weight: 500;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: transparent;
  border-color: transparent;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):active .p-tabview-nav-link {
  border-color: var(--color-basic-dark);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset var(--focus-ring);
  background-color: transparent;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--color-basic-dark);
  color: var(--color-basic-dark);
  background-color: transparent;
}
.p-tabview .p-tabview-nav-btn.p-link {
  color: var(--color-basic-dark);
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  box-shadow: inset var(--focus-ring);
}

.p-dialog {
  border-radius: 10px;
}
.p-dialog .p-dialog-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: var(--focus-ring);
}
.p-dialog .p-dialog-content {
}
.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-overlaypanel .p-overlaypanel-close {
  background: var(--color-blue);
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  box-shadow: var(--focus-ring);
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  box-shadow: var(--focus-ring);
}

@media screen and (max-width: 960px) {
  .p-menubar .p-menubar-button:focus {
    box-shadow: var(--focus-ring);
  }
}

.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: var(--focus-ring);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: var(--focus-ring);
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: var(--focus-ring);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: inset var(--focus-ring);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--color-blue);
  color: var(--color-blue);
}

.p-message .p-message-close:focus {
  box-shadow: var(--focus-ring);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  box-shadow: var(--focus-ring);
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  box-shadow: var(--focus-ring);
}

.p-chip .p-chip-remove-icon:focus {
  box-shadow: var(--focus-ring);
}

.p-tag {
  background: var(--color-blue);
}
.p-tag.p-tag-success {
  background-color: var(--color-green);
}
.p-tag.p-tag-warning {
  background-color: var(--color-yellow);
}
.p-tag.p-tag-danger {
  background-color: var(--color-red);
}

.p-inplace .p-inplace-display:focus {
  box-shadow: var(--focus-ring);
}

.p-progressbar .p-progressbar-value {
  background: var(--color-blue);
}

.p-terminal .p-terminal-input {
  font-family: var(--font-family);
}

.p-badge {
  background: $basic-grey;
}
.p-badge.p-badge-success {
  background-color: var(--color-green);
}
.p-badge.p-badge-warning {
  background-color: var(--color-yellow);
}
.p-badge.p-badge-danger {
  background-color: var(--color-red);
}

.p-tag {
  background: var(--color-blue);
}
.p-tag.p-tag-success {
  background-color: var(--color-green);
}
.p-tag.p-tag-warning {
  background-color: var(--color-yellow);
}
.p-tag.p-tag-danger {
  background-color: var(--color-red);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--color-blue);
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: var(--color-blue);
}

.p-confirm-popup.p-confirm-popup {
  border-radius: 10px;
}

.p-confirm-dialog-message {
  font-size: 1rem;
}

.p-placeholder {
  font-weight: 400;
  color: var(--color-text-grey);
}

.p-scrollpanel {
  &:not(:hover) > .p-scrollpanel-bar:not(:hover):not(:active) {
    opacity: 0;
  }

  & > .p-scrollpanel-bar {
    background-color: rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  & > .p-scrollpanel-bar-y {
    width: 6px;
  }

  & > .p-scrollpanel-bar-x {
    height: 6px;
  }
}

.p-splitter.p-splitter {
  .p-splitter-gutter {
    width: 5px !important;
    background-color: transparent;
    position: relative;
    
    &:after {
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color:  var(--color-light-grey);
      transition: width 150ms;
    }
  }

  .p-splitter-gutter-resizing {
    &:after {
      width: 3px;
    }
  }

  .p-splitter-gutter-handle {
    display: none;
  }
}

.p-tooltip {
  max-width: 400px;
}

@keyframes p-progress-spinner-color {
  0%,
  100% {
    stroke: #c9f0ff;
  }
}
