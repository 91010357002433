@import "./src/styles/helpers/_index.scss";
.Layout {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}

.Content {
  margin: 0 50px 50px 0;

  font-size: 12px;
  font-weight: 500;
  color: $text-dark-grey;
}

.SubTitle {
  font-size: 20px;
  font-weight: 500;
  color: $text-dark-blue;
}

.LogoEditor {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;

  .UploadButton.UploadButton {
    &, &:hover {
      border: 2px solid var(--border-color);
    }
  }  
}

.UploadLogo {
  &Icon {
    margin-right: 10px;
    color: rgb(31, 44, 61);
  }

  &Input {
    display: none;
  }
}

.Preview {
  margin-bottom: 15px;

  .SubTitle {
    color: $text-grey;
  }
}

.LogoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 330px;
  width: 300px;
  padding: 10px;

  font-size: 12px;
  font-weight: 500;
  color: $text-grey;
  user-select: none;

  border: 2px dashed $basic-light-grey;
  text-align: center;
}

.LogoImage {
  object-fit: contain;
  width: 100%;
  max-height: 100%;

  &Hidden {
    display: none;
  }
}
