@import "./src/styles/helpers/_index.scss";
.root {
  .actions {
    display: flex;
    grid-gap: 10px;

    :global(.p-button-icon) {
      font-weight: 700;
    }
  }
}
