@import "./src/styles/helpers/_index.scss";
.root.root {
  display: flex;
  justify-content: space-between;

  .replaceButton {
    margin-left: auto;
    align-self: flex-start;
    line-height: 16px;
    cursor: pointer;
  
    :global(.icon) {
      font-size: 24px;
    }
  }
}
