@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  &.greyTheme {
    .calendarInput {
      --focus-ring: none;
      background-color: var(--color-light-grey);
      border-color: var(--color-light-grey);
    }
  }

  :global(.p-inputwrapper) {
    width: 100%;
  }

  .calendarInput {
    padding-right: 20px;
    border: none;
    font-size: 12px;
    text-align: center;
    color: var(--color-basic-dark);

    &::-webkit-input-placeholder {
      color: var(--color-text-grey);
    }
  }

  .clearButton.clearButton.clearButton {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      background-color: transparent;
      color: var(--color-red);
    }
  }

  &Invalid {
    .calendarInput {
      border-bottom: 1px solid $red !important;
    }

    &.greyTheme {
      .calendarInput {
        background-color: var(--color-red);
        background-color: var(--color-red);
        color: var(--color-basic-white);
      }

      .clearButton.clearButton.clearButton {
        color: var(--color-basic-white);
      }
    }
  }
}

.calendarDropdown {
  height: 28px !important;
  line-height: 1;

  :global(.p-dropdown-label) {
    padding-right: 3px;
  }

  &.monthDropdown {
    width: 120px;
  }

  &.yearDropdown {
    width: 95px;
  }
}  