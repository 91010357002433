@import "./src/styles/helpers/_index.scss";
.root.root.root {
  --header-height: 65px;
  --header-separator-indent: 28px;
  --logo-height: 48px;

  @media (max-width: 1600px) {
    --header-separator-indent: 24px;
  }

  @media (max-width: 1300px) {
    --header-separator-indent: 20px;
    --logo-height: 32px;
  }

  @media (max-width: 1160px) {
    --logo-height: 24px;
  }

  @mixin separator {
    width: 1px;
    height: 25px;
    content: '';
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    background-color: $basic-dark-blue;
  }

  padding: 0 20px;
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  overflow: visible;
  background: $basic-dark;
  color: #fff;

  .logo {
    height: var(--logo-height);
    display: flex;
    align-items: center;
  }

  .info {
    margin-left: var(--header-separator-indent);
    padding: 0 calc(var(--header-separator-indent) - 14px);
    height: 100%;
    min-width: calc(260px + 2 * var(--header-separator-indent));
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media (max-width: 1160px) {
      min-width: 250px;
    }

    &::before {
      @include separator;
      left: 0;
    }

    &::after {
      @include separator;
      right: 0;
    }
  }

  .navMenu {
    margin-left: var(--header-separator-indent);
    height: calc(100% - 8px);
    flex: 1 1 auto;
  }

  .user {
    margin-left: var(--header-separator-indent);
    padding: 0 var(--header-separator-indent);
    height: 100%;
    position: relative;
    flex-shrink: 0;

    > [class*='User'] {
      height: 100%;
    }

    &::before {
      @include separator;
      left: 0;
    }

    &::after {
      @include separator;
      right: 0;
    }
  }

  .linkIcons {
    padding: 0 var(--header-separator-indent);
  }

  .skLink {
    padding-left: var(--header-separator-indent);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      @include separator;
      left: 0;
    }

    .skLogo {
      height: auto;
      width: 135px;
    }
  }

  .dropdown.dropdown.dropdown {
    --height: var(--header-height, 41px);
    --surface-color: #202c3e;
    --text-color: #fff;
    --border-color: #202c3e;
    --scrollbar-color: rgba(141, 141, 141, 0.5);
    --logo-size: 40px;

    width: 100%;
    justify-content: center;

    img {
      height: auto;
      border-radius: 0;
    }

    :global(.p-dropdown),
    :global(.p-dropdown .p-dropdown-filter) {
      height: $header-height;
      margin: 0;
      border-color: var(--border-color);
      background-color: var(--surface-color);
      font-size: 15px;
      box-shadow: none;
      text-align: center;
    }
  
    :global(.p-dropdown-label) {
      color: $text-white;
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  
    :global(.p-dropdown-panel) {
      margin-top: 0;
      transform: translate(-1px, calc(-1 * (var(--height) + 1px)));
  
      :global(.p-dropdown-header) {
        padding: 0;
        box-shadow: none;
        background-color: var(--surface-color);
      }
  
      :global(.p-dropdown-filter-icon) {
        display: none;
      }
  
      :global(.p-dropdown-items-wrapper) {
        background-color: var(--surface-color);
      }
      
      :global(.p-dropdown-items) {
        padding-top: 0;
        background-color: $basic-dark;
  
        :global(.p-dropdown-item) {
          padding-top: 10px;
          padding-bottom: 10px;
          min-height: 50px;
          display: flex;
          align-items: center;
          color: $text-white;
          font-size: 15px;
  
          &:hover {
            background-color: var(--color-primary);
            color: $text-white;
          }
  
          &:global(.p-highlight) {
            background-color: var(--color-primary-active);
          }
        }
      }
  
      .p-dropdown &, *::-webkit-scrollbar {
        width: 8px;
      }
  
      .p-dropdown &, *::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-color);
      }
    }
  }
}
