@import "./src/styles/helpers/_index.scss";
.Table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th:nth-child(1) {
    width: 20%;
  }
  th:nth-child(2) {
    width: 40%;
  }
  th:nth-child(3) {
    width: 10%;
  }
  th:nth-child(4) {
    width: 2%;
  }
  th,
  td {
    padding: 8px;
    font-size: 13px;
    vertical-align: middle;
    border: 1px solid black;

    &:last-child {
      text-align: center;
    }
  }

  th {
    width: 20%;
    text-align: center;
    font-size: 16px;
    font-weight: normal;

    &:nth-child(6),
    &:nth-child(7) {
      min-width: 50px;
    }
  }
}

.Check {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.RemoveButton {
  :global(.p-button-icon):before {
    padding: 10px 15px;
    font-size: 18px;
  }
}
