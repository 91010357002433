html {
  height: 100%;
  font-family: Rubik, sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;

  &:not([class*='webix']) {
    line-height: 1.2;
  }
}
