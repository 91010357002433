@import "./src/styles/helpers/_index.scss";
.Layout {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 50px auto 0;
}

.Content {
  padding: 2%;
  border-radius: 15px;

  background-color: $basic-white;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.RemoveButton {
  font-size: 18px;
  color: $red;
  opacity: 0.2;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: $text-dark-blue;
}

.Form {
  display: flex;
}

.Fieldset {
  flex-basis: 50%;
  margin-right: 20px;
  border: none;

  > legend {
    margin-bottom: 10px;

    font-weight: 500;
    font-size: 18px;
    color: $text-dark-blue;
  }
}

.FormGroup {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;

  &ForBudget {
    margin-bottom: 10px;

    span {
      padding-left: 15px;
    }
  }
}

.Field {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;

  position: relative;
  padding-bottom: 10px;

  font-weight: 500;
  font-size: 12px;
  color: $text-grey;

  > * {
    flex: 1 1 auto;
  }

  &Label {
    display: inline-block;
    margin-bottom: 14px;
    line-height: 20px;
  }

  textarea {
    width: 100%;
    height: 140px;
    margin-top: 8px;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    outline: none;
    resize: none;

    font-family: Rubik, sans-serif;
    font-size: 12px;
    line-height: 20px;
    background-color: $basic-light-grey;
  }
}

.FieldForTextarea {
  flex-basis: 100%;
}

.BudgetGroup {
  position: relative;
  padding: 10px;
  border-radius: 15px;

  &Empty {
    border: 1px solid $purple;
  }

  > .WarningMessage {
    bottom: -15px;
  }

  legend {
    margin-bottom: 20px;
  }

  .FormGroup {
    margin-bottom: 10px;
    padding-left: 30px;
  }
}

.AddOption {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 10px;
  border: none;
  border-radius: 50%;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background-color: $basic-dark-blue;
  color: $text-white;
  cursor: pointer;

  &Group {
    padding: 5px 15px;
  }

  &Label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $text-dark-blue;
  }
}

.ClearButton {
  position: absolute;
  bottom: 15px;
  right: 10px;
  cursor: pointer;

  &Right {
    right: -20px;
  }

  &::before {
    font-size: 11px;
    color: $red;
  }
}

.WarningMessage {
  position: absolute;
  bottom: -5px;

  font-weight: 400;
  font-size: 10px;
  color: $purple;
}

.ButtonGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 40px;

  &Item {
    margin: 10px 0;
    font-weight: 500;
    font-size: 14px;
    color: $text-dark-blue;

    span {
      padding-right: 10px;
    }
  }
}

.SaveButtons {
  margin: 20px 0;
}
