@import "./src/styles/helpers/_index.scss";
.root {
  margin: auto;
  cursor: pointer;

  &Active::before {
    color: var(--color-green);
  }
}

.dialog:global(.p-dialog) {
  :global(.p-dialog-content) {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    :global(.p-confirm-dialog-message) {
      margin-left: 0;
    }
  }

  :global(.p-confirm-dialog-reject) {
    &:hover, &:active {
      background-color: transparent !important;
      box-shadow: var(--focus-ring);
    }
  }
}

.nonVisible {
  display: none !important;
}
