@import "./src/styles/helpers/_index.scss";
.FlowChart {
  display: flex;
  flex-direction: column;
}

.FlowChartContainer {
  display: flex;
  user-select: none;
  position: relative;
}

.ArrowButton {
  flex: 0 0 24px;
  height: 24px;
  margin: 22px 12px;
  padding: 0;
  align-self: flex-start;

  font-size: 16px;
  color: #1c1c1c;

  background: none;
  border: 0;

  opacity: 0.6;
  outline: none;
  cursor: pointer;
  -webkit-text-stroke: 1px $basic-white;

  &:hover,
  &:focus {
    opacity: 1;
    color: $text-black;
  }
}

.Main {
  margin-bottom: 15px;
}

.Header {
  white-space: nowrap;
  margin: 0 0 5px 5px;
}

.NoDataMessage {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: $text-grey;
}

.Buttons {
  display: flex;
  column-gap: 20px;

  .Button.Button {
    &, &:hover, &:active {
      border: 1px solid var(--border-color);
    }
  }
}

.Month {
  &Container {
    display: inline-block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &Title {
    margin: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $text-dark-blue;
    text-transform: capitalize;
  }
}

.Weeks {
  margin: 0;
}

.Week {
  position: relative;
  display: inline-block;
  width: 65px;
  text-align: center;

  font-size: 12px;
  line-height: 30px;
  color: $text-dark-grey;
  opacity: 0.3;

  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 5px;
    border-right: 1px solid #aaa;
    content: '';
  }
}

.TimeLines {
  width: 100%;
  padding-top: 20px;
}

.Row {
  position: relative;
  height: $flowchart-row-height;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.FlowChartScale {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;

  &Label {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $text-dark-blue;
  }
}

.DataRangeLabel {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  max-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:global(.LinesEllipsis) {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30px;
  line-break: anywhere;
}

.DateRange {
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 0 10px;

  font-size: 12px;
  font-weight: 500;
  color: $text-black;

  overflow: hidden;
  white-space: normal;

  border-radius: 15px;
  background-color: $basic-light-grey;

  display: flex;
  align-items: center;

  cursor: pointer;

  &StatusActive {
    color: $text-white;
    background-color: $active;
    text-overflow: ellipsis;
  }

  &StatusPaused {
    color: $text-white;
    background-color: $paused;
  }

  &StatusCompleted {
    color: $text-white;
    background-color: $completed;
  }

  &StatusArchived {
    color: $text-black;
    background-color: $archived;
  }

  &StatusUnconfirmed {
    color: $text-black;
    background-color: $unconfirmed;
  }

  &StatusConfirmed {
    color: $text-white;
    background-color: $confirmed;
  }
}

.ScrollbarOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background: $basic-white;
  transition: all 0.4s ease;
  opacity: 0;
  z-index: -1;

  &Visible {
    opacity: 1;
    z-index: 0;
  }
}
