@import "./src/styles/helpers/_index.scss";
.root {
  display: grid;
  padding-top: 10px;
}

.setting {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 200px;
  align-items: center;
  padding: 12px;

  &Header {
    font-weight: 700;
    text-align: center;
  }

  :global(.p-inputswitch) {
    margin-right: 10px;

    &:global(.p-inputswitch-checked) :global(.p-inputswitch-slider) {
      background-color: var(--color-green) !important;
    }
  }

  &Invalid {
    :global(.p-inputswitch):global(.p-inputswitch-checked) :global(.p-inputswitch-slider) {
      background-color: var(--color-red-hover) !important;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 14px;
}
