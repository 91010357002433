@import "./src/styles/helpers/_index.scss";
.RadioButtons {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.Select {
  display: flex;
  margin-top: 10px;
}

.SelectWrapper {
  width: 100%;
  &:first-child {
    margin-right: 5px;
  }
}
