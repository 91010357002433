@import "./src/styles/helpers/_index.scss";
.root.root.root {
  :global(.p-dropdown-panel) {
    text-align: left;
  }

  :global(.p-dropdown-label) {
    margin-top: auto;
    margin-bottom: auto;
    height: max-content;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.isMenuVisible {
    box-shadow: var(--focus-ring);
  }

  &.isTabular {
    width: 100%;
  }

  &.smallSize {
    height: 32px;
  }

  &.mediumSize {
    height: 36px;
  }

  &.largeSize {
    height: 40px;
  }

  &.greyTheme {
    --focus-ring: none;
    background-color: var(--color-light-grey);
    border-color: var(--color-light-grey);

    :global(.p-placeholder) {
      color: var(--color-text-grey);
    }
  }
}
