@import "./src/styles/helpers/_index.scss";
.Layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 600px;
}

.Content {
  min-width: 600px;
  flex: 1 0 auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: $text-dark-blue;
}

.Form {
  padding: 20px 30px 30px;
  border-radius: 15px;

  background-color: $basic-white;
}

.Save {
  display: flex;
  justify-content: center;
}
