@import "./src/styles/helpers/_index.scss";
.root {
  width: 100%;
  height: var(--filter-row-height);
  display: grid;
  grid-template-columns: var(--template-columns);
  contain: size layout style paint;
  background-color: var(--header-background-color);
  font-size: 14px;
  font-weight: 700;
  outline: none;
  position: absolute;
}
