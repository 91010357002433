@import "./src/styles/helpers/_index.scss";
.root {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 7px;
  font-size: 12px;

  th {
    padding: 6px 12px;
    font-weight: 700;
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
    color: var(--color-text-grey);
    user-select: none;

    &:first-child {
      padding-left: 16px;
    }

    &[data-sort] {
      cursor: pointer;
    }

    &[data-sort-active=true] {
      color: var(--color-text-active-link);
    }
  }

  td {
    padding: 12px;
    color: var(--color-basic-dark);

    &:first-child {
      padding-left: 16px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.row {
  background-color: rgba(250, 250, 250, 0.9);
}

.sortingIcon {
  display: inline-block;
  padding: 0 6px;

  &::before {
    position: relative;
    top: 2px;
  }

  &Reverse {
    transform: rotate(180deg) translateY(2px);
    
    &::before {
      top: -1px;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
}

.action {
  position: relative;
  margin: 0 5px;
  font-size: 16px;
  text-decoration: none;
  color: var(--color-basic-dark);
  cursor: pointer;
}
