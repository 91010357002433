@import "./src/styles/helpers/_index.scss";
.root.root {
  &:global(.p-button.p-button-icon-only) {
    width: 2rem;
    height: 1.2rem;
    margin: auto;

    &:hover, &:focus {
      box-shadow: none;
    }
  }

  :global(.p-button-icon) {
    font-size: 12px;
    font-weight: 700;
  }

  :global(.p-button-icon.pi-play) {
    padding-left: 2px;
  }
}
