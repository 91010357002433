@import "./src/styles/helpers/_index.scss";
.handsontable.handsontable {
  font-size: 14px;

  .htCore th {
    .relative {
      padding: 2px 6px;
    }

    .select-all {
      margin: 2px 0;
      height: 100%;
      cursor: pointer;
    }

    .colHeader {
      white-space: normal;
    }

    .columnSorting.columnSorting {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:before {
        padding: 0;
        content: ' ';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: calc(100% + 6px);
        width: 10px;
        font-family: 'primeicons';
        background-image: none !important;
        color: #fff;
      }

      &.descending:before {
        content: '\E913';
      }
      &.ascending:before {
        content: '\E914';
      }
    }
  }

  .htCore.htCore td {
    max-width: 250px;
    word-break: break-word;
    vertical-align: middle;

    &.htDimmed .htAutocompleteArrow {
      display: none;
    }

    &.editable-cell {
      background-color: var(--color-beige);
    }

    &.row-changed:not(.htInvalid) {
      background-color: var(--row-selected-translucent-background-color);
    }

    &.cell-changed:not(.htInvalid) {
      background-color: var(--row-selected-hover-background-color);
    }

    &.warning-cell {
      text-align: center;

      &:before {
        content: '\E922';
        font-family: 'primeicons';
        font-size: 16px;
        font-weight: 700;
        vertical-align: middle;
        color: var(--color-yellow);
      }
    }

    &.reset-cell {
      padding: 0;
      text-align: center;
      vertical-align: middle;

      > * {
        padding: 6px 0;
        box-sizing: border-box;
        cursor: pointer;
        transition: color 150ms;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
    }
  }

  &.ht_clone_top,
  &.ht_clone_left {
    th {
      background-color: $basic-dark-blue;
      color: $basic-white;
      border-color: $basic-white;
      cursor: pointer;
      vertical-align: middle;

      > .relative {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.ht_clone_top_left_corner th {
    background-color: $basic-dark-blue;
  }

  .manualColumnResizer.active,
  .manualColumnResizer:hover {
    background-color: $basic-dark-blue !important;
  }

  .htCheckboxRendererInput {
    margin-right: 0;
  }

  th.ht__highlight {
    background-color: var(--color-primary-hover);
  }

  .ht__highlight.ht__active_highlight {
    background-color: var(--color-primary-active);
  }
}
