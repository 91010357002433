@import "./src/styles/helpers/_index.scss";
.root {
  min-width: 100px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-grey);
}
