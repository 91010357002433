@import "./src/styles/helpers/_index.scss";
.root.root.root {
  --border-color: transparent;
  --focus-ring: none;

  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;

  .title {
    color: var(--color-text-dark-blue);
    font-size: 12px;
    font-weight: 500;
  }

  .selectButton {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    & > :global(.p-button) {
      padding: 0;
      background-color: transparent;
      color: var(--color-text-grey);
      font-weight: 500;
      font-size: 12px;

      &:hover {
        color: var(--color-text-active-link);
      }

      &:global(.p-highlight),
      &:global(.p-highlight):hover {
        background-color: transparent;
        color: var(--color-text-active-link);
      }
    }
  }
}
