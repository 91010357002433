@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: auto;
  max-width: calc(100% - 50px);
  height: var(--creative-height);
  font-size: 16px;
  text-align: center;

  &Loading {
    width: var(--creative-height);

    .progressbar {
      width: 80%;
    }
  }
  
  &NoContent {
    width: var(--creative-height);
    box-shadow: 1em 0 10em hsla(0, 0%, 0%, 0.1) inset;
    color: var(--color-text-grey);

    :global(.pi)::before {
      font-size: 28px;
    }
  }

  iframe {
    width: var(--creative-width);
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;

    &:fullscreen {
      cursor: -webkit-zoom-out;
      cursor: zoom-out;
    }
  }
}
