@import "./src/styles/helpers/_index.scss";
.root.root {
  &[class~='p-sidebar'] {
    top: auto;
    bottom: 0;
    width: calc(100% - 20px) !important;
    height: calc(100vh - #{$header-height} - 30px) !important;
    margin-top: auto;
    padding: 0;
    border-top-left-radius: 15px;
    background-color: #f9f9f9;
    overflow: hidden;

    & [class~='p-sidebar-header'],
    & [class~='p-sidebar-content'] {
      padding: 0;
    }
  }

  & [class~='p-sidebar-close'] {
    margin-top: 4px;
    margin-right: 4px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
