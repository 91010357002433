@import "./src/styles/helpers/_index.scss";
@import '../../mixins.scss';

.field {
  @include field;
}

.label {
  cursor: pointer;

  &Disabled {
    color: var(--color-text-grey);
    cursor: initial;
  }
}

.input {
  flex: 1;

  input {
    @include input;

    &:disabled {
      background-color: transparent;
    }
  }
}
