@import "./src/styles/helpers/_index.scss";
.ReportContainer {
  position: absolute;
  width: 100%;
  top: 170px;
  bottom: 0;

  @include breakpoint(1600px) {
    top: 230px;
  }
}

.Report {
  padding-bottom: calc(100vh - 170px);

  @include breakpoint(1600px) {
    padding-bottom: calc(100vh - 230px);
  }
}
