@import "./src/styles/helpers/_index.scss";
.root.root {
  --creative-height: 400px;
  --creative-width: calc(var(--creative-height) * 16 / 9);

  display: grid;
  grid-template-columns: 300px max-content auto;
  column-gap: 10px;
  height: var(--creative-height);

  .addButton {
    width: auto;
    margin-right: 10px;
    padding: 3px;
    line-height: 8px;
  
    :global(.pi-plus) {
      font-size: 8px;
    }
  }

  .divider {
    width: 1px;
    margin-right: 40px;
    background-color: var(--color-grey);
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: hidden;
}

.scrollbar {
  width: 100%;
  height: calc(100% - 50px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}
