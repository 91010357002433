@import "./src/styles/helpers/_index.scss";
.ContentAutoWidth {
  float: left;

  min-width: 100%;

  @include clearfix;
}

.Track {
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 6px;

  &WithHeaderOffset {
    top: $header-height;
  }
  
  &Hidden {
    display: none;
  }
}

.DraggableView {
  cursor: grab;
  &Dragged {
    cursor: grabbing;
  }
}
