@import "./src/styles/helpers/_index.scss";
.root {
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

.excessTable {
  flex: 1 1 auto;
  position: relative;

  [class~='p-panel-toggler'] {
    padding-top: 4px;
    width: 60px;
    height: 30px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--rdg-header-background-color);
    color: #fff;
    clip-path: circle(50% at 50% 100%);

    &:hover {
      color: var(--row-selected-hover-background-color);
    }
  }
}

.panel {
  [class~='p-toggleable-content'] [class~='p-panel-content'] {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
