@import "./src/styles/helpers/_index.scss";
.Layout {
  width: 100%;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 30px;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 27.5px;
  color: $text-dark-blue;
}

.Content {
  border-radius: 15px;
  background-color: $basic-white;
}

.Settings {
  display: flex;
  padding: 40px 80px;
  border-bottom: 2px solid #f2f2f2;

  &Header {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $text-dark-blue;

    &Preview {
      color: $text-grey;
    }
  }
}

.ColorSettings {
  flex-basis: 50%;
}

.CardWrapper {
  padding: 10px;
  border-radius: 10px;
  background-color: $basic-light-blue;
}
