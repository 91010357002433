@import "./src/styles/helpers/_index.scss";
.root {
  --color-background: var(--color-basic-white);
  --color-main-text: var(--color-text-dark-grey);
  --color-additional-text: var(--color-text-grey);

  padding: 20px;
  width: 275px;
  height: 275px;
  min-height: 260px;
  position: relative;
  border-radius: 15px;
  background-color: var(--color-background);
  color: var(--color-main-text);
  font-size: 12px;
  transition: all 0.4s ease 0s;

  .favoriteButton {
    position: absolute;
    top: 6px;
    right: 12px;
  }

  .name {
    height: 48px;
    margin: 5px 0;
    font-size: 20px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .status {
    margin: 10px 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .budgetScale {
    margin-top: 30px;
  }
}
