@import "./src/styles/helpers/_index.scss";
.Pagination {
  &Buttons {
    padding: 0 10px;
    white-space: nowrap;
    text-align: center;
  }

  &Button {
    padding: 6px;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: white;
      border-radius: 3px;
    }

    &Icon {
      display: inline-block;
      margin: 0px 3px;

      &::before {
        vertical-align: middle;
      }
    }

    &Disabled {
      opacity: 0.1;
      cursor: default;

      &:hover {
        background-color: initial;
      }
    }
  }

  &Value {
    margin: 0 10px;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    color: $text-grey;
  }

  &Of {
    margin: 0 10px;
  }
}
