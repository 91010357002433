@import "./src/styles/helpers/_index.scss";
.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  max-width: 100%;

  .pivotContainer { 
    flex: 1;

    :global(.webix_layout_line) {
      :global(.webix_el_button .webix_el_box) {
        display: flex;
        justify-content: center;
      }

      :global(.webix_el_button:first-child .webix_el_box) {
        justify-content: flex-start;
      }

      :global(.webix_el_button:last-child .webix_el_box) {
        justify-content: flex-end;
      }

      :global(.webix_button) {
        width: max-content;
        padding: 0 12px;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    gap: 14px;
  }
}
