@import "./src/styles/helpers/_index.scss";
.Toggle {
  display: none;

  @include breakpoint-tablet-portrait {
    &:checked ~ .Container {
      width: 50px;
    }

    &:checked ~ .Container > .Header,
    &:checked ~ .Container > .Menu {
      visibility: hidden;
      transform: translate(-100%) scale(0, 1);
      transform-origin: left center;
    }

    &:checked ~ .Container > .ToggleButton {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: $administration-sidebar-width;
  height: 100vh;
  font-weight: 500;
  background-color: $basic-dark-blue;
  color: $text-active-link;
  transition: all 0.5s;
}

.ToggleButton {
  position: absolute;
  top: 42px;
  right: 10px;
  z-index: 1;
  transform: translateY(-50%);
  display: none;
  width: 25px;
  font-size: 25px;
  cursor: pointer;

  @include breakpoint-tablet-portrait {
    display: flex;
  }
}

.Header {
  padding: 30px 10px;
  border-bottom: 2px solid $text-dark-grey;
  text-shadow: 1px 1px 2px #001f2b;
  font-size: 18px;
  text-align: center;
  transition: all 0.5s ease-out;

  @include breakpoint-tablet-portrait {
    padding-right: 40px;
  }
}

.Menu {
  margin: 0;
  padding-top: 30px;
  padding-left: 0;
  list-style: none;
  transition: all 0.5s ease-out;

  @include breakpoint-tablet-portrait {
    top: 84px;
  }

  &Item {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  &Link {
    display: inline-block;
    width: 100%;
    padding: 20px 20px 20px 40px;
    line-height: 1.5;
    text-decoration: none;
    color: $basic-light-blue;

    &:hover,
    &Active {
      border-left: 4px solid $text-active-link;
      color: $text-active-link;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &Active {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
