@import "./src/styles/helpers/_index.scss";
.root {
  .title {
    font-size: 14px;
    font-weight: 500;
    color: $text-dark-blue;
  }

  .content {
    display: flex;
    align-items: center;
    grid-gap: 16px;

    .colorButton {
      display: block;
    }

    .info {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      line-height: 14px;

      .label {
        color: $text-dark-grey;
        font-weight: 500;
      }

      .hexColor {
        color: $text-grey;
        font-weight: 400;
      }
    }
  }
}

.confirmPopup.confirmPopup {
  overflow: hidden;

  :global(.p-confirm-popup-content) {
    padding: 0;
  }

  :global(.p-confirm-popup-message) {
    margin: 0 0 0.5rem 0;
  }

  :global(.p-confirm-popup-footer) {
    padding: 10px;
    display: flex;
    justify-content: center;

    :global(.p-button) {
      font-size: 12px;
    }

    :global(.p-confirm-popup-reject) {
      &:hover, &:active {
        background-color: transparent;
        box-shadow: var(--focus-ring);
      }
    }
  }
}
