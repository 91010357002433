@import "./src/styles/helpers/_index.scss";
.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-white);

  &:hover {
    .remainingTime {
      opacity: 0;
    }

    .playbackButton {
      opacity: 1;
    }
  }

  .remainingTime {
    transition: opacity 150ms;
    font-size: 14px;
  }

  .playbackButton {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 150ms;
    --icon-color: #fff;
  }
}
