@import "./src/styles/helpers/_index.scss";
.root {
  &[class~='p-dialog'] {
    [class~='p-dialog-header'] {
      [class~='p-dialog-title'] {
        padding-left: 25px;
        text-align: center;
      }
    }

    [class~='p-dialog-content'] {
      padding-bottom: 20px;
    }
  }
}
