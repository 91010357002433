@import "./src/styles/helpers/_index.scss";
.root.root.root.root.root.root {
  :global(.p-button) {
    --focus-ring: none;
    padding: 6px 12px;
    border: none;
    border-radius: 0 !important;
    border-bottom: 2px solid transparent;
    color: var(--color-text-grey);
    font-size: 14px;
    font-weight: 500;

    &:global(.p-highlight) {
      color: var(--color-text-dark-blue);
      border-bottom-color: var(--color-basic-dark);
    }

    &:active {
      border-bottom-color: var(--color-basic-dark);
    }
  }
}
