@import "./src/styles/helpers/_index.scss";
.rdg {
  --background-color: var(--rdg-background-color);
  --header-background-color: var(--rdg-header-background-color);
  --header-row-height: 50px;

  border-width: 0;
  user-select: text;
  overflow-y: scroll;
  color: $text-dark-grey;

  * {
    border-width: 0;
  }

  &,
  * {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      height: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      border: 3px solid transparent;
      cursor: pointer;
    }
  }

  .p-badge {
    min-width: 0.5rem;
    height: 0.5rem;
  }
}

.rdg-row {
  background-color: #fff;
  box-shadow: -1px 0px 5px 0 rgba(0, 0, 0, 0.1);
}

.rdg-header-row {
  color: #fff;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  contain: unset;
  z-index: 4;
}

.rdg-cell {
  padding-left: 6px;
  padding-right: 6px;

  &[aria-selected='true'] {
    box-shadow: none;
  }

  & + &:before {
    display: block;
    content: '';
    width: 1px;
    height: 50%;
    position: absolute;
    left: 0;
    top: 25%;
    background-color: #eee;
  }
}

.rdg-cell[role='columnheader'] {
  contain: unset;
  position: relative;
  overflow: unset;

  &:before {
    background-color: #eee;
    top: 0;
    height: calc(100% + var(--filter-row-height));
  }

  &:after {
    height: calc(100% + var(--filter-row-height));
  }
}
