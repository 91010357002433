@import "./src/styles/helpers/_index.scss";
.root {
  height: calc(100vh - var(--header-height, 0px));
  padding: 25px 5% 0;
  display: flex;
  align-items: stretch;
  grid-gap: 20px 20px;
  background-color: var(--color-light-blue);
  color: var(--color-text-dark-blue);

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    .title {
      grid-area: title;
      margin: 0;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 27px;
    }

    .filters {
      grid-area: filters;
    }

    .footer {
      grid-area: footer;
    }
  }

  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .addCampaign {
      grid-area: addCampaign;
      margin-left: auto;
      text-decoration: none;

      :global(.p-button-icon) {
        font-size: 10px;
        font-weight: bold;
      }
    }

    .pagination {
      grid-area: pagination;
      padding-left: 20px;

      .total {
        grid-area: total;
        font-size: 12px;
        font-weight: 500;

        .totalValue {
          margin-left: 5px;
          color: var(--color-text-grey);
        }
      }

      .paginator {
        grid-area: paginator;
      }

      .sorter {
      }
    }

    .list {
      margin-top: 20px;
      font-size: 16px;
      overflow: hidden;

      :global(.p-scrollpanel-wrapper) {
        padding-bottom: 20px;
      }

      :global(.p-scrollpanel-content) {
        padding-left: 20px;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 30px;
      }

      .campaignLink {
        height: min-content;
        text-decoration: none;
        border-radius: 15px;
        transition: box-shadow 300ms;

        &:hover {
          box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.57);
        }
      }
    }
  }
}
