@import "./src/styles/helpers/_index.scss";
.root {
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;

  > span {
    flex-basis: 33.33%;

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
}

.percent {
  font-size: 9px;
  color: var(--color-additional-text, var(--color-text-grey));
}

.factValue {
  color: inherit;

  &.success {
    color: var(--color-green);
  }

  &.warning {
    color: var(--color-red);
  }
}

.bar {
  display: flex;
  height: 5px;
  background-color: var(--color-light-grey);
}

.scale {
  height: 100%;

  &.success {
    background-color: var(--color-green);
  }

  &.warning {
    background-color: var(--color-red);
  }
}
