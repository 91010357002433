@import "./src/styles/helpers/_index.scss";
.root.root.root.root {
  --icon-width: 34px;
  --focus-ring: none;

  &,
  &:hover {
    width: 128px;
    min-height: 32px;
    padding: 2px calc(var(--icon-width) + 7px) 2px 7px;
    background-color: var(--color-basic-white);
    color: var(--color-text-dark-blue);
    border: none;
    box-shadow: 0 5px 7px -7px rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;

    :global(.p-button-icon-right) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      margin: 0;
      padding-left: 3px;
      width: var(--icon-width);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-primary);
      color: var(--color-basic-white);
      clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%);
    }
  }
}
