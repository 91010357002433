@import "./src/styles/helpers/_index.scss";
.Layout {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  width: 80%;
  margin: 50px auto 0;
  min-width: 850px;

  @media (max-width: 850px) {
    padding: 0 25px;
  }
}

.Content {
  min-width: 800px;
  flex: 1 0 auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: $text-dark-blue;
}

.Link {
  text-decoration: none;
}

.Description {
  margin: 1.5rem 0;
  font-size: 18px;
  text-align: justify;

  a {
    text-decoration: none;
    font-weight: 500;
    color: $text-active-link;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Table {
  width: 100%;
  border-collapse: collapse;

  font-size: 13px;

  &Container {
    padding: 20px 30px 30px;
    border-radius: 15px;

    background-color: $basic-white;
  }

  &Cell {
    padding: 10px 20px;

    &Header {
      font-weight: 500;
      text-align: left;
      color: $text-grey;
    }

    &Centered {
      text-align: center;
    }

    &Compact {
      width: 200px;

      @include breakpoint-tablet {
        width: auto;
      }
    }
  }

  &Row {
    &:nth-child(2n + 1) {
      background-color: rgba(238, 238, 238, 0.7);
    }

    &:hover {
      background-color: $basic-grey;
    }

    .TableCell {
      &:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      > .Checkbox {
        cursor: pointer;
      }
    }
  }
}

.IconDelete {
  display: flex;
  justify-content: center;
  align-items: center;

  &Button {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: -8px;

    font-size: 20px;
    color: $red;
    cursor: pointer;
  }
}

.RowIcons {
  display: flex;
  justify-content: center;

  > * {
    padding-left: 8px;
    padding-right: 8px;

    text-decoration: none;
    color: $basic-dark;
    cursor: pointer;
  }
}

.TokenInfo {
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
  }
}

.UpdateTokenButton {
  margin-left: 10px;

  text-decoration: none;
  color: $basic-dark;
  cursor: pointer;
  transform: translateY(0.5px);

  &Spinning {
    animation: spinning 0.6s cubic-bezier(0.12, 0.67, 0.48, 0.38) infinite;
  }

  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
}

.Status {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 50%;

  &Active {
    background-color: $green;
  }

  &NotReady {
    background-color: $red;
  }
}
