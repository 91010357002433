@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  align-items: center;
}

.icon {
  color: var(--color-yellow);
}

.tooltip {
  :global(.p-tooltip-arrow) {
    display: none !important;
  }
}
