@import "./src/styles/helpers/_index.scss";
.MediaCard {
  margin: 0 auto;
  height: 100%;
  border-radius: 15px;
  padding: 20px;
  color: $text-white;
  user-select: none;

  &Web {
    background: $media-type-web;

    .Icon {
      -webkit-text-stroke: 0.3px $media-type-web;
    }
  }

  &TV {
    background: $media-type-tv;

    .Icon {
      -webkit-text-stroke: 1px $media-type-tv;
    }
  }

  &Outdoor {
    background: $media-type-outdoor;

    .Icon {
      -webkit-text-stroke: 1px $media-type-outdoor;
    }
  }

  &Radio {
    background: $media-type-radio;

    .Icon {
      -webkit-text-stroke: 0.3px $media-type-radio;
    }
  }

  &Press {
    background: $media-type-press;

    .Icon {
      -webkit-text-stroke: 1px $media-type-press;
    }
  }

  &Blank {
    background: $basic-light-grey;
    color: $text-grey;

    .Value,
    .Currency {
      opacity: 0.5;
    }

    .Icon {
      -webkit-text-stroke: 0;
      opacity: 0.15;
    }
  }
}

.Icon {
  font-size: 32px;
  text-align: center;
}

.Value {
  width: 100%;
  margin-top: 15px;
  font-size: 51px;
  line-height: 60px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 1300px) {
    font-size: 40px;
  }
}

.Currency {
  width: 100%;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  text-align: center;
}

.MediaType {
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  opacity: 0.5;
  text-align: center;
}

.ActiveCampaignsCount {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

.NumberCount {
  margin-top: 5px;
}
