@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 50%;
}

.textarea {
  width: 100%;
  height: 200px;
}
