@import "./src/styles/helpers/_index.scss";
.root.root.root {
  width: auto;
  border-radius: 0;

  &.iconOnly {
    line-height: 0;
  }

  &:global(.p-button.p-button-text) {
    border: none;
    background-color: transparent;
    box-shadow: none !important;
  }

  :global(.p-button-icon) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
