@import "./src/styles/helpers/_index.scss";
.Table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th,
  td {
    padding: 8px;
    font-size: 13px;
    vertical-align: middle;
    border: 1px solid black;

    &:nth-child(6) {
      text-align: center;
    }
  }

  th {
    width: 20%;
    text-align: center;
    font-size: 16px;
    font-weight: normal;

    &:nth-child(6),
    &:nth-child(7) {
      min-width: 50px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      width: 6%;
    }
  }
}

.Check {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.RemoveButton {
  :global(.p-button-icon):before {
    font-size: 18px;
  }
}
