@import "./src/styles/helpers/_index.scss";
.tabs.tabs.tabs {
  overflow-x: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  [class~='p-tabview-nav'] {
    width: 100%;
    border: none;
    background-color: $basic-grey !important;
  }

  [class~='p-tabview-panels'] {
    padding-top: 10px;
    flex: 1 1 auto;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
  }

  [class~='p-tabview-panel'] {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  [class~='p-unselectable-text'] {
    [class~='p-tabview-nav-link'] {
      justify-content: center;
      padding: 1rem 1rem 0.8rem;
      color: $text-grey;
      background-color: $basic-grey !important;
      border-top-left-radius: 10px;
      font-weight: 500;

      &:focus {
        box-shadow: none;
      }
    }

    &[class~='p-highlight'] {
      z-index: 10;

      [class~='p-tabview-nav-link'] {
        color: $text-middle-grey;
        background-color: #efefef !important;

        &:hover {
          color: $text-middle-grey;
        }
      }
    }
  }
}
