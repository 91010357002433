@import "./src/styles/helpers/_index.scss";
.root {
  padding: 25px calc(20px + 4.5%) 35px calc(20px + 4.5%);
  min-width: 640px;
  position: relative;
  border-radius: 15px;
  font-size: 15px;
  color: var(--color-basic-dark);
  background-color: var(--color-basic-white);

  @media (max-width: 1600px) {
    padding-left: calc(20px + 2%);
    padding-right: calc(20px + 2%);
  }

  @media (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header {
    padding: 22px 80px 22px 0;
    display: flex;
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 2rem;
    color: var(--color-text-dark-blue);
    @include wrap-lines(2.6rem, 3);
  }

  .favoriteButton {
    position: absolute;
    top: 24px;
    right: 30px;
  }

  .editLink {
    margin-left: 10px;
    padding: 0 16px;
    height: 38px;
    display: inline-flex;
    text-decoration: none;
    color: var(--color-text-black);
  }

  .editButton {
    font-size: 22px;
    line-height: 1;
    opacity: 0.5;
    transition: opacity 150ms;

    &:hover {
      opacity: 0.6;
    }
  }

  .data {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: minmax(60px, 1fr) minmax(60px, 1fr);
    grid-gap: 30px;
  }

  .brandLink {
    color: var(--color-basic-dark);
    text-decoration: none;
  }
}
