@import "./src/styles/helpers/_index.scss";
.calendar.calendar {
  width: 100%;
  
  .calendarInput {
    padding: 6px 2px;
    border-radius: 0;
    border-bottom: 1px solid var(--color-grey);
    text-align: left;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      border-bottom-color: #b3b3b3;
    }
  }
}
