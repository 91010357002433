@import "./src/styles/helpers/_index.scss";
.root {
  height: var(--title-row-height);
  width: 100%;
  padding-left: 6px;
  position: absolute;
  top: 0;
  font-size: 14px;
  border-top: 1px solid #fff;
  background-color: var(--data-grid-title-row-color);
}
