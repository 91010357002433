@import "./src/styles/helpers/_index.scss";
.root.root {
  width: 1000px;
  max-width: 90%;

  &FirstStep {
    width: 500px;

    .footer {
      padding-top: 0;
    }
  }

  :global(.p-dialog-header) {
    display: none;
  }

  .header {
    padding-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  :global(.p-dialog-content) {
    min-height: calc(min(580px, 60vh));
    padding: 1.5rem 1.5rem 1.8rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    padding-top: 12px;
  }
}
