@import "./src/styles/helpers/_index.scss";
.root.root {
  padding-right: 6px;
  display: grid;
  grid-template-areas:
    'message           countdownButton'
    'reportErrorButton countdownButton';
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  align-items: center;
  align-content: center;
  justify-items: flex-start;
  justify-content: space-between;

  .message {
    grid-area: message;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .reportErrorButton.reportErrorButton {
    margin-top: 10px;
    grid-area: reportErrorButton;
    color: var(--color-text-active-link);
    font-weight: 500;

    &:hover {
      color: var(--color-text-white);
    }
  }

  .countdownButton {
    margin-left: 14px;
    grid-area: countdownButton;
  }
}
