@import "./src/styles/helpers/_index.scss";
.log {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .buttonGroup.buttonGroup {
    display: flex;
    grid-gap: 10px;

    .clearButton {
      margin-left: 6px;
      margin-right: 20px;
    }

    .toggleButton {
      &[class~='p-togglebutton'] {
        color: inherit;
        background-color: #fff;
        border-color: var(--border-color);
        box-shadow: none;
      }

      &Active {
        background-color: #e9ecef !important;
        text-decoration: line-through;
      }
    }
  }
}

.toggleButtonTooltip.toggleButtonTooltip {
  :global(.p-tooltip-text) {
    text-align: center;
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 20px;
}

.info {
  padding-left: 4px;
  font-size: 16px;
}

.link {
  color: inherit;

  > div {
    word-break: break-word;
  }
}
