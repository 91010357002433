@import "./src/styles/helpers/_index.scss";
.Layout {
  width: 50%;
  margin: 50px auto 0;
  min-width: 850px;

  @media (max-width: 850px) {
    padding: 0 25px;
  }
}

.Content {
  position: relative;
  padding: 5%;
  border-radius: 15px;
  background-color: $basic-white;
  overflow: hidden;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: $text-dark-blue;
}

.CabinetSelect {
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 20px;
  font-size: 12px;
}

.Form {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}

.Label {
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
  color: $text-grey;
}

.Field {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;

  position: relative;
  padding-bottom: 10px;

  > * {
    flex: 1 1 auto;
  }

  &Input {
    margin-top: 14px;
  }

  &TypeCheckbox {
    margin-top: 15px;
    align-items: flex-start;

    .FieldInput {
      padding: 5px 0;
    }
  }
}

.ButtonGroup {
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 40px;

  .TokenButton {
    margin-left: 30px;
  }
}

.WarningMessage {
  position: absolute;
  bottom: -5px;

  font-weight: 400;
  font-size: 10px;
  color: $purple;
}

.Footer {
  position: absolute;
  bottom: 0;
}
