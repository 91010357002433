@import "./src/styles/helpers/_index.scss";
.Brands {
  width: 100%;
  position: relative;
}

.Header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0 30px;
  margin-bottom: 20px;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.15;
  color: $text-dark-blue;
}

.BrandSelector {
  flex: 0 0 180px;
  margin: 0 5px 0 25px;

  font-size: 12px;
  line-height: 14px;
}

.Content {
  width: 100%;
  min-height: 250px;
  padding: 25px 35px;
  border-radius: 15px;

  font-size: 13px;
  background-color: $basic-white;
}

.LogoSettings {
  position: relative;
}

.ColorSection {
  margin-top: 2.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid $basic-light-grey;
}

.SaveButtons {
  margin: 20px 0;
  justify-content: flex-end;
}
