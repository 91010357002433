@import "./src/styles/helpers/_index.scss";
.BrandView {
  width: 80%;
  min-width: 850px;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;

  @media (max-width: 1300px) {
    width: 100%;
    padding: 0 25px;
  }
}

.Main {
  width: 100%;
  flex: 1 1 auto;
}

.BrandSection {
  min-height: 345px;
  margin-top: 50px;
  padding: 30px;

  background: $text-white;
  border-radius: 15px;
}

.BudgetsHeader {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.BrandTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 27.6px;
  color: $text-dark-blue;
}

.BrandSelector {
  flex: 0 0 150px;
  margin: 0 5px 0 25px;

  font-size: 12px;
  line-height: 14px;
}

.BrandEdit {
  &Link {
    flex: 0 0 auto;
    text-decoration: none;
    color: $text-black;
  }

  &Icon {
    margin: 0 3px;

    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
  }
}

.YearSelector {
  flex: 0 0 125px;
  margin-left: auto;

  font-size: 12px;
  line-height: 14px;
}

.MediaCards {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.MediaCardWrapper {
  flex: 1 0 170px;
  height: 210px;
  margin: 0 15px;
  overflow: hidden;

  @media (max-width: 1300px) {
    margin: 0 5px;
    min-width: 140px;
    flex-shrink: 1;
  }
}

.FlowChart {
  &Container {
    margin: 45px auto;
    padding: 30px;
    background: $basic-white;
    border-radius: 15px;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Title {
    margin: 10px 0;

    font-size: 24px;
    font-weight: 500;
    line-height: 27.6px;
    color: $text-dark-blue;
  }

  &Wrapper {
    margin-top: 27px;
    width: 100%;
    overflow: auto;
  }
}

.AddCampaignButton {
  align-self: flex-end;
  text-decoration: none;
}

.PowerBILoader {
  margin: auto;
}
