@import "./src/styles/helpers/_index.scss";
.root {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-gap: 10px;
  grid-auto-flow: column;
}

.hidden.hidden {
  display: none;
}
