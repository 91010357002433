@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.title {
  white-space: break-spaces;
  line-height: 1.4;
  text-align: center;
  word-break: break-word;
}

.filterButtonWrapper {
  width: 25px;
  height: 32px;
  position: relative;
  display: flex;
}

.filterButton {
  width: 25px !important;
  height: 25px !important;

  &:hover > [class~='p-button-icon'] {
    color: initial;
  }

  & > [class~='p-button-icon'] {
    color: #fff;
    transition: color 300ms;
    font-size: 14px;
  }
}

.sortingButton.sortingButton {
  &[class~='p-button'] {
    margin-left: 4px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;

    > [class~='p-button-icon'] {
      font-size: 12px;
    }

    &:hover {
      border: none;
      background-color: transparent;
      color: $lazur;
    }
  }

  &Active > [class~='p-button-icon'] {
    color: $lazur;
  }
}
