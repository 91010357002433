@import "./src/styles/helpers/_index.scss";
.root {
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;

  .table {
    flex: 1 1 auto;
    position: relative;
    max-height: calc(100vh - 200px);

    :global(.p-panel-toggler) {
      padding-top: 4px;
      width: 60px;
      height: 30px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--rdg-header-background-color);
      color: #fff;
      clip-path: circle(50% at 50% 100%);

      &:hover {
        color: var(--row-selected-hover-background-color);
      }
    }
  }

  .panel {
    :global(.p-toggleable-content .p-panel-content) {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .link {
    margin-bottom: 20px;
    align-self: flex-start;
    display: inline-flex;
    align-items: center;
    grid-gap: 2px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-blue);

    :global(.pi) {
      font-size: 16px !important;
      font-weight: bold;
      margin-left: -4px;
    }
  }
}
