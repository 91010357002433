@import "./src/styles/helpers/_index.scss";
.root.root.root {
  .table {
    border-collapse: separate;
    border-spacing: 0 7px;
  }

  :global(.p-datatable-thead) {
    th {
      padding: 5px;
      background-color: transparent;
      border: none;

      :global(.p-column-header-content) {
        justify-content: center;
        font-size: 12px;
        color: var(--color-text-grey);
        text-align: center;
      }
    }
  }

  .cell {
    padding: 0 5px;
    height: 50px;
    background-color: var(--color-grey);
    color: var(--color-text-dark-grey);
    font-size: 12px;
    text-align: center;

    &:first-of-type {
      padding: 0 5px 0 10px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-of-type {
      padding: 0 10px 0 5px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .textCell {
    @include wrap-lines(100%, 2);
    border-radius: 3px;
    --color-red: rgba(237, 85, 101, 0.25);

    &.invalid {
      background-color: var(--color-red);
      box-shadow: 0 7px 0 0 var(--color-red), 0 -7px 0 0 var(--color-red);
    }
  }

  .inputText {
    --focus-ring: none;
    width: 100%;
    padding: 6px;
    border-color: transparent;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    animation: editor-appearance 300ms;

    &.invalid {
      border-color: var(--color-red);
    }
  }

  @keyframes editor-appearance {
    0% {
      background-color: var(--color-grey);
    }
    100% {
      background-color: var(--color-basic-white);
    }
  }
}
