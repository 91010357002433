@import "./src/styles/helpers/_index.scss";
.root {
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  position: relative;
  display: grid;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  align-items: center;
  background-color: var(--data-grid-title-row-color);
  color: #fff;
  font-weight: 500;
  grid-gap: 20px;
  flex-grow: 1;
}
