@import "./src/styles/helpers/_index.scss";
.root {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-gap: 10px;
  grid-template-columns: 220px 200px 240px auto;

  .statusFilter {
    &.isActive {
      font-weight: 500;
    }
  }
}
