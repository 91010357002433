@import "./src/styles/helpers/_index.scss";
.root.root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;

  .icon {
    font-size: 12px;
  }
}
