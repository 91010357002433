@import "./src/styles/helpers/_index.scss";
.root.root {
  &,
  &:hover,
  &:active,
  &:focus {
    margin: 0 auto;
    padding: 0;
    text-decoration: underline;
    background-color: transparent !important;
    box-shadow: none;
  }
}

.dash {
  margin: 0 auto;
}
