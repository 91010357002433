@import "./src/styles/helpers/_index.scss";
.root {
  max-width: 300px;
  font-size: 12px;

  &.disabled {
    display: none;
  }

  &.static {
    top: var(--pr-tooltip-top, 0) !important;
    left: var(--pr-tooltip-left, 0) !important;
    right: unset !important;
    bottom: unset !important;
  }

  :global(.p-tooltip-arrow) {
    display: none;
  }
}
