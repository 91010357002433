@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 90%;
  margin: 40px auto 0;

  :global(.p-tabview) {
    height: 100%;

    :global(.p-tabview-panels) {
      height: calc(100% - var(--footer-height));
      padding: 0;
      background-color: transparent;
    }

    :global(.p-tabview-nav) {
      border: none;

      li {
        padding: 6px 12px 2px;
      }

      :global(.p-tabview-nav-link) {
        border-bottom-width: 3px;
      }
    }

    :global(.p-tabview-panel) {
      height: 100%;
    }
  }
}
