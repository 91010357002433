@import "./src/styles/helpers/_index.scss";
.root.root {
  flex: 1;
  display: flex;
  border-radius: 16px;
  background-color: rgba($basic-light-grey, 0.5);

  .uploader {
    width: 100%;
    margin: 24px;

    &Disabled {
      .label {
        color: rgba($text-grey, 0.6);
      }
    }
  }

  .header {
    height: 200px;
  }

  .uploadButton:global(.p-button) {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    font-size: 48px;

    &, &:hover {
      border: 1px solid var(--color-text-grey);
      box-shadow: none;
      background-color: transparent;
      color: var(--color-text-grey);
    }
  }

  .label {
    font-weight: 500;
    text-align: center;
    color: var(--color-text-grey);
    opacity: 0.6;
  }

  .content:global(.p-fileupload-content) {
    height: 80px;
    padding: 30px 0 0;
    border: none;
    border-radius: 0;
    background-color: transparent;

    :global(.p-progressbar) {
      display: none;
    }
  
    :global(.p-fileupload-row) {
      button {
        &, &:hover {
          border: none;
          box-shadow: none;
          background-color: transparent;
          color: var(--color-text-grey);
        }

        &:hover {
          color: var(--color-basic-dark);
        }
      }
    }

    :global(.p-fileupload-row > div) {
      padding: 0 0 10px 10px;
      text-align: center;
      @include wrap-lines(100%, 3);
    }

    :global(.p-fileupload-row:last-child > div) {
      padding-bottom: 0;
    }

    :global(.p-fileupload-row > div:first-child) {
      text-align: left;
    }

    :global(.p-fileupload-row > div:last-child) {
      text-align: right;
    }
  }
}
