@import "./src/styles/helpers/_index.scss";
.root {
  width: 100%;
  display: grid;
  grid-column-gap: 15px;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-template-columns: auto 25px 16px;
  align-items: center;
  color: $text-dark-grey;
  font-size: 14px;
}

.icon {
  color: $blue;
}

.ok {
  color: $green;
}

.warning {
  color: $yellow;
}

.error {
  color: $red;
}
