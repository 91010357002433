@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  .controls {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    grid-gap: 15px;

    .button {
      font-size: 16px;

      :global(.p-button-icon) {
        font-weight: 700;
      }
    }
  }

  .saveButton {
    margin: 5px;
    align-self: flex-end;
    font-size: 16px;
  }

  .regionalCreativesItem {
    margin-top: 20px;

    & + .regionalCreativesItem {
      margin-top: 30px;
    }
  }
}
