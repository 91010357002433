@import "./src/styles/helpers/_index.scss";
.root {
  padding-bottom: 20px;
  display: grid;
  grid-template-areas:
    'summary actions'
    'filters actions';
  justify-content: space-between;
  justify-items: flex-start;
  grid-gap: 20px 15px;

  .summary {
    margin-left: 14px;
    grid-area: summary;
    align-self: flex-end;
  }

  .actions {
    grid-area: actions;
    align-self: flex-end;
  }

  .filters {
    grid-area: filters;
    align-self: flex-end;
  }
}
