@import "./src/styles/helpers/_index.scss";
@import '../../mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;

  .centeredContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .shiftedContainer {
    margin-left: 5px;
  }

  .label {
    cursor: pointer;

    &Disabled {
      color: var(--color-text-grey);
      cursor: initial;
    }
  }

  .calendar {
    margin-left: 5px;
    width: 150px;

    input {
      @include input;
    }
  }

  .checkbox {
    margin-right: 3px;
    margin-top: -3px; //centering
  }

  .field {
    @include field;
  }

  .select {
    flex: 1;
    max-width: 280px;

    :global(.p-multiselect-items-wrapper) {
      max-width: 300px;
    }

    :global(.p-multiselect-item) {
      overflow: unset;
    }

    :global(.p-multiselect-items-wrapper),
    :global(.p-multiselect-footer) {
      margin-bottom: -2px;
    }
  }

  .levels {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-left: 20px;
  }

  .cabinetsFilter {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;

    .field:first-child {
      justify-content: flex-start;
    }
  }
}

.bottomPanel {
  top: auto !important;
  bottom: 0;
}
