@import "./src/styles/helpers/_index.scss";
.root.root {
  width: 400px;

  :global(.p-dialog-header) {
    padding-bottom: 0;
  }
}

.title {
  font-size: 1rem;
  text-align: center;
}

.label {
  display: block;
  margin: 30px 0 10px;
  font-weight: 500;
  cursor: pointer;

  &Disabled {
    font-weight: 400;
    color: #999;
    cursor: default;
  }
}

.footer {
  display: flex;
  justify-content: space-evenly;
}
