@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  display: grid;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  justify-items: flex-start;
  grid-gap: 10px;
  grid-auto-flow: column;
}

.badge:global(.p-badge) {
  justify-self: flex-start;
}
