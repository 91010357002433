@import "./src/styles/helpers/_index.scss";
.root {
  .title {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-text-grey);
  }

  .value {
    margin-top: 5px;
    font-size: 17px;
    color: var(--color-basic-dark);
  }
}
