@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 10px;
  width: 280px;
  position: relative;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;

  .label {
    margin-bottom: 4px;
    display: inline-block;
    min-height: 28px;
    font-weight: 500;
  }

  :global(.p-disabled) {
    opacity: 1;

    :global(.p-dropdown-trigger) {
      display: none;
    }
  }
}
