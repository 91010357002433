@import "./src/styles/helpers/_index.scss";
.root.root {
  --indent: calc(var(--header-separator-indent, 20px) / 1.4);

  background-color: transparent;
  border: none;

  .link {
    display: block;
    text-decoration: none;
    color: inherit;
    height: max-content;
    width: max-content;
    font-size: 0;

    & > span {
      font-size: 22px;
    }
  }

  :global(.p-menuitem) {
    font-size: 0;
  }

  :global(.p-menubar-root-list) {
    grid-gap: var(--indent);
    align-items: center;
  }
}
