@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;

  .controls {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .templateDownloadButtons {
    margin-top: -30px;
  }

  .creatives {
    display: flex;
    flex-direction: column;
  }

  .editing {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    grid-gap: 10px;

    .button {
      font-size: 16px;

      :global(.p-button-icon) {
        font-weight: 700;
      }
    }

    .deleteButton {
      margin-left: 10px;
    }
  }

  .saveButton {
    margin: 5px;
    align-self: flex-end;
    font-size: 16px;
  }

  .regionalCreativesItem {
    margin-top: 20px;

    & + .regionalCreativesItem {
      margin-top: 30px;
    }
  }

  :global(.p-tabview-nav-container) {
    display: none;
  }
}
