@import "./src/styles/helpers/_index.scss";
.root {
  display: block;
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
}

.tooltip {
  &[class~='p-tooltip'] {
    max-width: 400px;

    [class~='p-tooltip-arrow'] {
      border-width: 0 0.25rem 0.25rem;
    }

    [class~='p-tooltip-text'] {
      box-shadow: none;
      border-radius: 8px;
    }
  }
}
