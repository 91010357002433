@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
  height: 100%;
}

.buttonGroup {
  position: absolute;
  top: -44px;
  right: 0;
  display: flex;
  column-gap: 10px;
}

.pagination {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 10px;
}
