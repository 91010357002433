@import "./src/styles/helpers/_index.scss";
.root {
  padding-bottom: 20px;
  display: grid;
  grid-template-areas: 'filters actions';
  justify-content: space-between;
  justify-items: flex-start;
  grid-gap: 10px 15px;

  .actions {
    grid-area: actions;
    align-self: flex-end;
  }

  .filters {
    grid-area: filters;
    align-self: flex-end;
  }
}
