@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex: 1 1 auto;
  z-index: 1;

  div[role='grid'] {
    height: 100%;
  }
}

.titleRow {
  border-radius: 10px 10px 0 0 !important;
  background-color: var(--data-grid-title-row-color) !important;
}

.titleCell {
  padding-right: 0 !important;
  display: flex;
}

.firstCell {
  padding-left: 10px !important;
}

.cellWrapper {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  height: 100%;
  text-align: center;
}

.lastRowInGroup {
  border-radius: 0 0 10px 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.filterRowContainer {
  width: var(--row-width);
  position: sticky;
  top: var(--header-row-height);
  z-index: 3;
}

.pinnedRowContainer {
  width: var(--row-width);
  position: sticky;
  top: calc(var(--header-row-height) + var(--filter-row-height));
  z-index: 1;
}
