@mixin line-height-initial {
  line-height: 1.42857143;
}

@mixin wrap-lines($line-height, $line-count: 2) {
  max-height: $line-height * $line-count;
  white-space: normal;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
}

@mixin labels-container {
  .label {
    display: inline-block;

    margin: 0 2px;
  }

  margin-left: -2px;
  margin-right: -2px;
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin orientation($orientation: landscape) {
  @media (orientation: $orientation) {
    @content;
  }
}

@mixin breakpoint($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin breakpoint-min-width($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin breakpoint-widths($min-width, $max-width) {
  @media (min-width: $min-width), (max-width: $max-width) {
    @content;
  }
}

@mixin breakpoint-max-height($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin breakpoint-max-size($width, $height) {
  @media (max-width: $width) and (max-height: $height) {
    @content;
  }
}

@mixin breakpoint-min-size($width, $height) {
  @media (min-width: $width) and (min-height: $height) {
    @content;
  }
}

@mixin breakpoint-tablet() {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin breakpoint-tablet-portrait() {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin breakpoint-mobile() {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin breakpoint-mobile-portrait() {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin tab-content() {
  position: relative;
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: $basic-white;
}

@mixin tab-header() {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}

@mixin tab-head() {
  margin-top: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $text-dark-grey;
}

@mixin tab-description() {
  font-size: 14px;
  color: $text-grey;
}

@mixin datepicker() {
  display: flex;
  white-space: nowrap;

  input {
    padding-left: 15px;
  }
}
