@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
  white-space: nowrap;
  line-height: 20px;
  display: flex;
  grid-gap: 3px;

  .title {
    color: var(--color-additional-text);
  }

  .value {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
