@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  font-size: 12px;

  .label {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    min-height: auto;
    font-weight: 500;
    text-align: left;
    padding-left: 16px;
    font-size: 14px;

    .warningIcon {
      cursor: auto;
    }
  }

  .calendar {
    width: 100%;

    .calendarInput {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
