@import "./src/styles/helpers/_index.scss";
.root {
  background-color: var(--color-light-grey);
  font-size: 12px;

  &.isCurrent {
    background-color: rgba(49, 178, 85, 0.15);
  }

  .row {
    td {
      padding: 0 5px;
      overflow-wrap: break-word;
      text-align: center;
      color: var(--color-basic-dark);

      &:first-child {
        padding-left: 30px;

        @include breakpoint(1680px) {
          padding-left: 10px;
        }
      }

      &:last-child {
        padding-right: 30px;

        @include breakpoint(1680px) {
          padding-right: 10px;
        }
      }
    }

    &:first-child {
      td {
        padding-top: 5px;

        &:first-child {
          border-top-left-radius: 15px;
        }

        &:last-child {
          border-top-right-radius: 15px;
        }
      }
    }

    &:last-child {
      td {
        padding-bottom: 5px;

        &:first-child {
          border-bottom-left-radius: 15px;
        }

        &:last-child {
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .nameCell {
    padding: 8px 10px;

    > div:first-child {
      padding-left: 10px;
      padding-bottom: 5px;
      white-space: nowrap;
      font-weight: 500;
      text-align: left;
    }
  }

  .statusCell {
    width: 140px;
    vertical-align: bottom;
  }

  .statusSelect {
    margin: 4px;
  }

  .fileActions {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    @include breakpoint-tablet {
      grid-gap: 5px;
    }
  }

  .fileAction {
    font-size: 20px;
  }

  .links {
    padding: 3px 0;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;

    @include breakpoint-tablet-portrait {
      flex-wrap: wrap;
    }
  }

  .linksGroup {
    padding: 3px 0;
    display: flex;
    grid-gap: 5px;
  }

  .wrapped {
    flex-wrap: wrap;
  }
}
