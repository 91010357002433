@import "./src/styles/helpers/_index.scss";
.Row {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.Label {
  font-size: 16px;
  $width: 130px;
  width: 80px + $width;

  &Level1 {
    width: 80px + $width;
  }
  &Level2 {
    width: 40px + $width;
    margin-left: 40px;
  }
  &Level3 {
    width: 40px + $width;
    margin-left: 40px;
  }
}

.Input {
  flex: 1 1 1rem;
}

.Check {
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  cursor: pointer;

  &Text {
    margin-left: 10px;
    font-size: 14px;
    user-select: none;
  }
}
