@import "./src/styles/helpers/_index.scss";
.root {
  padding: 2px 6px 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.filterButtonWrapper {
  width: 25px;
  height: 32px;
  position: relative;
  display: flex;
}

.filterButton {
  width: 25px !important;
  height: 25px !important;

  &.isActive {
    [class~='p-button-icon'] {
      color: $lazur;
    }
  }

  &:hover > [class~='p-button-icon'] {
    color: initial;
  }

  & > [class~='p-button-icon'] {
    color: #fff;
    transition: color 300ms;
    font-size: 14px;
  }
}
