@import "./src/styles/helpers/_index.scss";
.root.root.root {
  --text-color: var(--color-text-middle-grey);
  --surface-color: var(--color-basic-white);
  --dropdown-items-surface-color: var(--color-basic-white);
  --logo-size: 30px;

  width: auto;
  min-width: 100px;

  :global(.p-dropdown-label) {
    font-size: 12px;
    color: var(--text-color);
  }

  :global(.p-placeholder) {
    color: var(--color-text-grey);
  }

  :global(.p-dropdown) {
    width: 100%;
    background-color: var(--surface-color);
    color: var(--text-color);
    box-shadow: none !important;

    :global(.p-dropdown-trigger) {
      color: var(--text-color);
    }

    :global(.p-dropdown-trigger-icon) {
      color: currentColor;
    }
  }

  .panel {
    width: calc(100%);
    box-shadow: none;
    background-color: transparent;

    :global(.p-dropdown-header) {
      padding: 0;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background-color: var(--color-basic-white);
    }

    :global(.p-dropdown-filter) {
      width: calc(100% - 36px);
      padding-right: 0;
      background-color: var(--color-basic-white);
      background-origin: content-box;
      border-width: 0;
      box-shadow: none;
      color: var(--text-color);
      display: inline-block;
      margin: 4px;
      padding: 6px 12px;
      font-size: 12px;
    }

    :global(.p-dropdown-filter-container) {
      display: flex;
      box-shadow: none;
      border-radius: 2rem;
      cursor: auto;
    }

    :global(.p-dropdown-items-wrapper) {
      background: var(--dropdown-items-surface-color);
      color: var(--text-color);
      border: 0 none;
      border-radius: 0 0 3px 3px;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    :global(.p-dropdown-item) {
      color: var(--text-color);

      &:global(.p-highlight) {
        color: var(--text-color);
      }

      &:hover {
        color: var(--text-color);
      }
    }

    :global(.p-dropdown-empty-message) {
      color: var(--text-color);
      font-size: 12px;
    }
  }

  &.isTabular {
    width: 100%;
  }

  &.isInvalid {
    :global(.p-dropdown) {
      border-color: var(--color-red) !important;
    }
  }

  &.greyTheme {
    --surface-color: var(--color-light-grey);
  }

  &.inlineMode {
    :global(.p-dropdown) {
      height: auto !important;
      border: none;
      border-bottom: 1px solid var(--color-grey);
      border-radius: 0;
      box-shadow: none;

      &:hover {
        border-bottom-color: #b3b3b3;
      }

      &:global(.p-focus) {
        box-shadow: 0 4px 2px -2px rgba(96, 125, 139, 0.07);
      }

      :global(.p-dropdown-label),
      :global(.p-placeholder) {
        padding: 6px 2px;
      }
  
      :global(.p-dropdown-trigger) {
        width: auto;
      }

      :global(.p-dropdown-trigger-icon) {
        font-size: 10px;
        color: #ccc;
      }
    }
  }

  .logoItem {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    height: 100%;

    .logo {
      width: var(--logo-size);
      height: var(--logo-size);
      padding: 2px;
      border-radius: 50%;
    }
  }

  :global(.p-dropdown-item) .logoItem {
    .logo {
      position: absolute;
    }
  
    .label {
      padding-left: calc(var(--logo-size) + 10px);
    }
  }
}
