@import "./src/styles/helpers/_index.scss";
.root.root.root {
  .header {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    .label {
      display: flex;
      align-items: center;
      user-select: none;
      flex-basis: 33%;

      .toggleButton {
        margin-right: 30px;

        :global(.p-button-icon) {
          font-size: 28px;
        }
      }

      .cityLabel {
        margin-right: 10px;
        font-weight: 700;
      }

      .cityValue {
        text-transform: uppercase;
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      flex-basis: 33%;
    }
  }

  :global(.p-panel-content) {
    padding: 10px 0 0 0;
    border: none;
  }
}
