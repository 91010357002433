@import "./src/styles/helpers/_index.scss";
.root.root.root {
  padding: 10px;
  width: 280px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  font-size: 12px;

  .label {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    min-height: 28px;
    font-weight: 500;
    text-align: center;
  }

  .calendar {
    width: 100%;

    :global(.p-inputtext) {
      height: 40px;
      border-radius: 2rem;
      text-align: center;
      font-size: 12px;
    }
  }
}
