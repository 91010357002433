@import "./src/styles/helpers/_index.scss";
.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  [class^='HeaderCell-module_header'] {
    margin: auto;
    padding: 6px;
  }

  .pagination {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 20px;

    .info {
      padding-left: 4px;
      font-size: 16px;
    }
  }
}
