@import "./src/styles/helpers/_index.scss";
.CampaignTooltip {
  position: fixed;
  width: 250px;
  min-height: 100px;
  padding: 25px 25px 25px 31px;

  font-size: 12px;
  line-height: 21px;
  color: $basic-dark;
  white-space: nowrap;

  background: $basic-white;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  pointer-events: none;

  transform: translate(10px, -50%);

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
  }
}

.Header {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  @include wrap-lines(17px);
}

.Status {
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InfoLabel {
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &[data-tooltip]:after {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-tooltip);
    padding: 7px;
    border-radius: 5px;

    white-space: normal;
    background-color: hsla(0, 0%, 20%, 0.8);
    color: $text-white;
  }

  &[data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

.BudgetLabel {
  margin: 15px 0 0 0;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  overflow: hidden;
  text-overflow: ellipsis;
}
