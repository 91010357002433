@import "./src/styles/helpers/_index.scss";
.root.root.root {
  :global(.p-tabview-nav-container) {
    padding-left: 15px;

    :global(.p-tabview-nav) {
      border-color: transparent;
    }
  }
  & > :global(.p-tabview > .p-tabview-panels) {
    padding: 30px;
    border-radius: 15px;

    @media (max-width: 1400px) {
      padding: 20px;
    }

    :global(.p-tabview-panel) {
      min-height: 400px;
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 20px;
    width: max-content;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-text-dark-grey);
    position: relative;

    .downloadReportButton {
      width: max-content;
      position: absolute;
      left: calc(100% + 20px);
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}
