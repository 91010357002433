@import "./src/styles/helpers/_index.scss";
.root {
  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }

  .value {
    margin-top: 5px;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-main-text);
    white-space: nowrap;
  }
}
