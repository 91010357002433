@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 40px auto 0;

  @include breakpoint(1600px) {
    width: 90%;
  }
}

.content {
  flex: 1;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 2.3rem;
  color: var(--color-primary);
}

.panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 36px;
  margin: 36px 0;

  @include breakpoint-tablet-portrait {
    flex-direction: column;
  }
}

.filters {
  @include breakpoint-tablet-portrait {
    order: 2;
  }
}

.tableContainer {
  height: calc(100vh - var(--header-height) - var(--footer-height) - 280px);
}

.footer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
}
