@import "./src/styles/helpers/_index.scss";
.root {
  padding: 3px 10px;
  border-radius: 4px;
  background-color: var(--color-status-base);
  font-size: 12px;
  display: inline-block;

  &.activeStatus {
    color: var(--color-text-white);
    background-color: var(--color-status-active);
  }

  &.archivedStatus {
    color: var(--color-text-black);
    background-color: var(--color-status-archived);
  }

  &.completedStatus {
    color: var(--color-text-white);
    background-color: var(--color-status-completed);
  }

  &.confirmedStatus {
    color: var(--color-text-white);
    background-color: var(--color-status-confirmed);
  }

  &.pausedStatus {
    color: var(--color-text-white);
    background-color: var(--color-status-paused);
  }

  &.unconfirmedStatus {
    color: var(--color-text-black);
    background-color: var(--color-status-unconfirmed);
  }
}

.icon {
  margin-left: 10px;
}
