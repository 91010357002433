@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  grid-gap: 10px;

  .leftPanel {
    width: 18%;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    flex: 0 0 auto;
  }

  .divider {
    width: 1px;
    min-width: 1px;
    background-color: var(--color-grey);
  }

  .rightPanel {
    flex: 1 1 auto;

    & > :global(.p-tabview-nav-container) {
      display: none;
    }

    & > :global(.p-tabview-panels) {
      padding: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
    }
  }
}
