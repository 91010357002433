@import "./src/styles/helpers/_index.scss";
.root {
}

.buttons {
  padding: 4px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;

  .button {
    padding: 6px 12px;
  }
}

.calendarInput.calendarInput {
  padding-right: 3px;

  &.isDateEqualToCampaignDate {
    font-weight: 500;
  }
}

.calendarDropdown {
  line-height: 1;

  :global(.p-dropdown-label) {
    padding-right: 3px;
  }

  &.monthDropdown {
    width: 120px;
  }

  &.yearDropdown {
    width: 95px;
  }
}
