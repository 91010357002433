@import "./src/styles/helpers/_index.scss";
.root {
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.label {
  display: block;
  color: inherit;
  transition: color 200ms;
}

.button.button.button {
  position: absolute;
  width: 100%;
  height: 100%;

  :global(.p-splitbutton-defaultbutton) {
    display: none;
  }
}

.menuButton.menuButton.menuButton {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: inherit;

  &, &:hover, &:focus, &:active {
    border: none;
    background-color: transparent;
    box-shadow: none !important;
  }

  :global(.p-button-icon) {
    line-height: 0;
  }
}
