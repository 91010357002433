@import "./src/styles/helpers/_index.scss";
.Overlay {
  position: fixed;
  z-index: 1110;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#4e4e4e, 0.6);
}

.Notification {
  max-width: 700px;
  min-width: 300px;
  padding: 30px 60px;
  background-color: #fff;
  line-height: 1.5rem;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.Button {
  display: block;
  width: fit-content;
  padding: 8px 17px;
  margin-left: 10px;
  border-radius: 4px;
  border: none;
  background-color: #419acc;
  color: #fff;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  &:-moz-focus-inner {
    border: none;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
