@import "./src/styles/helpers/_index.scss";
.root {
  display: flex;
  gap: 12px;
}

.filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  .clearButton {
    justify-self: end; 
  }
}

.calendar {
  width: 100%;
  
  input:global(.p-inputtext) {
    height: 36px;
    border: var(--border);
    font-size: 13px;
    text-align: left;

    &::-webkit-input-placeholder {
      color: #6c757d;
    }
    &:-moz-placeholder {
      color: #6c757d;
    }
    &::-moz-placeholder {
      color: #6c757d;
    }
    &:-ms-input-placeholder {
      color: #6c757d;
    }
  }

  :global(.p-button.p-component .pi-times) {
    font-size: 13px !important;

    &:not(:hover) {
      --icon-color: #495057;
    }
  }
}

.input input:global(.p-inputtext) {
  font-size: 13px;
}
