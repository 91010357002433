@import "./src/styles/helpers/_index.scss";
.root.root {
  --focus-ring: 0 5px 20px -7px rgba(0, 0, 0, 0.7);
  --color-button-text: var(--button-text, var(--color-basic-white));

  flex-shrink: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px;

  &:hover {
    transition: all 0.4s ease 0s;
  }

  &:focus {
    box-shadow: none;
  }

  &.hasNoPadding {
    padding: 0;
    width: auto;
  }

  :global(.p-button-icon) {
    color: var(--icon-color);
  }

  &.smallSize {
    padding: 5px 10px;
  }

  &.bigSize {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.lightTheme {
    --color-primary: var(--color-basic-white);
    --color-primary-hover: var(--color-basic-white);
    --color-primary-active: var(--color-basic-white);
    --color-button-text: var(--button-text, var(--color-basic-dark));
  }

  &.transparentTheme {
    --color-primary: transparent;
    --color-primary-hover: transparent;
    --color-primary-active: transparent;
    --color-button-text: var(--button-text, var(--color-text-grey));
  }

  &.successTheme {
    --color-primary: var(--color-azure);
    --color-primary-hover: var(--color-azure);
    --color-primary-active: var(--color-azure);
    --border-color: var(--color-primary);
  }
}
