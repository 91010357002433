@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;
}

.Input {
  display: block;
  width: 100%;
  line-height: 14px;
  padding: 5px 0px;
  border: none;
  border-bottom: 1px solid $basic-grey;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;

  font-family: Rubik, sans-serif;
  color: $basic-dark;

  &:hover {
    border-bottom-color: #b3b3b3;
  }

  &.NoBorder {
    border-bottom-color: transparent;
  }

  &.WithError,
  &:invalid {
    box-shadow: none;
    border-bottom-color: $purple;
  }

  &Disabled {
    background-color: var(--color-grey);
    opacity: 0.6;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &Round {
    padding: 16px 20px;
    background: #f0f0f0;
    border: 2px solid transparent;
    border-radius: 30px;

    font: normal 16px/1.33 Roboto, Arial, sans-serif;
    color: $text-black;

    &.WithError {
      border: 2px solid $purple;
    }
  }
}
