@import "./src/styles/helpers/_index.scss";
.root {
  position: relative;

  &.isTabular {
    line-height: 1rem;
    width: 100%;
  }

  &.isActive {
    .input[class~='p-inputtext'] {
      background-color: $basic-white;
    }

    .searchIcon {
      color: #6c757d;
    }

    .clearIcon {
      color: var(--color-red);
    }
  }

  &.isValueEqualToFilterValue {
    .searchIcon {
      cursor: initial;
    }
  }

  &.isActive.isValueEqualToFilterValue {
    .input[class~='p-inputtext'] {
      font-weight: 500;
    }
    .clearIcon {
      font-weight: bold;
    }
    .searchIcon {
      font-weight: 500;
    }
  }

  &.darkTheme {
    .input[class~='p-inputtext'] {
      background-color: var(--rdg-header-background-color);
      border-color: $basic-white;
      color: $basic-white;
    }

    .searchIcon {
      color: $basic-white;
    }

    .clearIcon {
      color: $basic-white;

      &:hover {
        color: var(--color-red);
      }
    }
  }

  &.darkTheme.isActive {
    .searchIcon {
      color: $lazur;
    }
  }

  &.greyTheme {
    .input[class~='p-inputtext'] {
      --focus-ring: none;
      background-color: var(--color-light-grey);
      border-color: var(--color-light-grey);
    }
  }

  &.mediumSize {
    :global(.p-inputtext) {
      height: 36px;
    }
  }

  &.smallSize {
    :global(.p-inputtext) {
      height: 32px;
    }
  }

  &.xsmallSize {
    :global(.p-inputtext) {
      height: 28px;
    }
  }
}

.input[class~='p-inputtext'] {
  padding-right: 46px;
  width: 100%;
}

.icons {
  height: 16px;
  display: grid;
  align-items: center;
  grid-template-columns: 16px 16px;
  grid-template-areas: 'clear search';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.searchIcon[class~='pi'] {
  grid-area: search;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.searchIcon[class~='pi-filter'],
.searchIcon[class~='pi-filter-fill'] {
  color: var(--color-secondary);
}

.clearIcon[class~='pi'] {
  grid-area: clear;
  font-size: 0.8rem;
  color: #495057;

  &:hover {
    color: var(--color-red);
    cursor: pointer;
  }
}
